import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { Layout, Menu, Breadcrumb, Dropdown, Avatar, Card } from 'antd';
import * as Icon from '@ant-design/icons';
import styled from 'styled-components';

import { logout, toggleMenu } from '../actions';
import logo from '../images/thangloi.jpeg';
import './admin.css';

const {
  Header, Content, Footer, Sider,
} = Layout;
const SubMenu = Menu.SubMenu;

export const Logo = styled.div`
  width: 80%;
  height: 50px;
  margin: ${props => (props.collapsed ? '8px auto' : '20px auto')};
  background-image: url(${logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Paper = styled(Card).attrs({
  hoverable: true,
  bordered: false,
})`
  min-height: calc(100vh - 200px);
  cursor: unset !important;
`;

const profileMenu = handleLogout => (
  <Menu>
    <Menu.Item key="0">
      <Icon.UserOutlined />
      <span role='button' onClick={() => navigate('/admin/account')}>Tài khoản</span>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <Icon.LogoutOutlined />
      <span role='button' onClick={handleLogout}>Đăng xuất</span>
    </Menu.Item>
  </Menu>
);

class AdminLayout extends React.Component {
  handleLogout = () => {
    this.props.logout();
    navigate('/admin');
  }

  render() {
    const { location = '/admin/user', collapsed, toggleMenu } = this.props; // eslint-disable-line
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          theme='light'
          trigger={null}
        >
          <Logo collapsed={collapsed} onClick={() => navigate('/')} />
          <Menu
            defaultSelectedKeys={[location]}
            defaultOpenKeys={[location.substring(0, location.indexOf('-') !== -1 ? location.indexOf('-') : location.length)]}
            mode="inline"
            onSelect={({ key }) => navigate(key)}
          >
            {/* <Menu.Item key='/admin/dashboard'>
              <Icon type="pie-chart" />
              <span>Tổng quan</span>
            </Menu.Item> */}
            <Menu.Item key='/admin/user'>
              <Icon.TeamOutlined />
              <span>Người dùng</span>
            </Menu.Item>
            <SubMenu
              key="/admin/product"
              title={<span><Icon.AppstoreOutlined /><span>Sản phẩm</span></span>}
            >
              <Menu.Item key="/admin/product">Quản lý sản phẩm</Menu.Item>
              <Menu.Item key="/admin/product-category">Quản lý danh mục</Menu.Item>
            </SubMenu>
            <SubMenu
              key="/admin/article"
              title={<span><Icon.BarsOutlined /><span>Bài viết</span></span>}
            >
              <Menu.Item key="/admin/article">Quản lý bài viết</Menu.Item>
              <Menu.Item key="/admin/article-category">Quản lý danh mục</Menu.Item>
              <Menu.Item key="/admin/shareholder-meeting">Đại hội Cổ Đông</Menu.Item>
            </SubMenu>
            <SubMenu
              key="/admin/config"
              title={<span><Icon.SettingOutlined /><span>Cấu hình</span></span>}
            >
              <Menu.Item key="/admin/config-display">Cấu hình chung</Menu.Item>
              <Menu.Item key="/admin/config-home">Trang chủ</Menu.Item>
              <Menu.Item key="/admin/section-banner">Banner trang chủ</Menu.Item>
              <Menu.Item key="/admin/config-story">C.chuyện Thắng Lợi</Menu.Item>
              <Menu.Item key="/admin/config-distribution">Hệ thống phân phối</Menu.Item>
              <Menu.Item key="/admin/config-contact">Thông tin liên hệ</Menu.Item>
            </SubMenu>
            <Menu.Item key='/admin/media'>
              <Icon.PictureOutlined />
              <span>Thư viện ảnh</span>
            </Menu.Item>
            <Menu.Item key='/admin/contact-form'>
              <Icon.ContactsOutlined />
              <span>Form liên hệ</span>
            </Menu.Item>
            <SubMenu
              key='/admin/order'
              title={<span><Icon.ShoppingCartOutlined /><span>Đơn hàng</span></span>}
            >
              {/* <Menu.Item key="11">Đang chờ xử lý</Menu.Item> */}
              <Menu.Item key='/admin/order-all'>Tất cả đơn hàng</Menu.Item>
            </SubMenu>
            {/* <SubMenu
              key="report"
              title={<span><Icon type="bar-chart" /><span>Báo cáo</span></span>}
            >
              <Menu.Item key="13">Báo cáo doanh thu</Menu.Item>
              <Menu.Item key="14">Thống kê sản phẩm</Menu.Item>
              <Menu.Item key="15">Thống kê khách hàng</Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            {collapsed ? <Icon.MenuUnfoldOutlined
              className="trigger"
              onClick={toggleMenu}
            /> : <Icon.MenuFoldOutlined className="trigger"
            onClick={toggleMenu} />}
            <Menu
              mode="horizontal"
              style={{ lineHeight: '64px', float: 'right' }}
            >
              <Menu.Item key="1">
                <Dropdown overlay={profileMenu(this.handleLogout)} trigger={['click']}>
                  <Avatar icon='user' />
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Thang Loi Corp © 2018 | Developed by <a href='https://innoteq.vn' target='__blank'>Innoteq Ltd</a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default connect(state => ({
  userInfo: state.user.userInfo,
  collapsed: state.setting.menuCollapsed,
}), {
  logout,
  toggleMenu,
})(AdminLayout);
