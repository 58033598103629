import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate, Link } from "@reach/router";
import { Form, Input, Button, Layout } from "antd";
import * as Icon from "@ant-design/icons";

import { registerUser } from "../../actions";
import { Logo } from "../../layouts/admin";

class Register extends Component {
  formRef = React.createRef();

  handleSubmit = (event) => {
    // event.preventDefault();
    this.formRef.current
      ?.validateFields()
      .then((values) =>
        this.props.registerUser(values, () => navigate("/user"))
      );
  };

  render() {
    const { isLogged } = this.props;

    if (isLogged) {
      navigate("/user/dashboard");
    }

    return (
      <Layout style={{ minHeight: "100vh", paddingTop: "18vh" }}>
        <Layout.Content
          style={{ minWidth: 300, maxWidth: 500, margin: "0 auto" }}
        >
          <Logo style={{ marginBottom: 50 }} />
          <Form
            onFinish={this.handleSubmit}
            className="login-form"
            ref={this.formRef}
          >
            <Form.Item
              name="fullname"
              rules={[
                { required: true, message: "Vui lòng điền họ và tên của bạn!" },
              ]}
            >
              <Input
                prefix={
                  <Icon.UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Họ và tên"
              />
            </Form.Item>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Vui lòng điền tên tài khoản!" },
              ]}
            >
              <Input
                prefix={
                  <Icon.UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Tên tài khoản"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Vui lòng điền email!" }]}
            >
              <Input
                prefix={
                  <Icon.MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Địa chỉ email"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Vui lòng điền số điện thoại!" },
              ]}
            >
              <Input
                prefix={
                  <Icon.PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Số điện thoại"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Vui lòng điền mật khẩu!" }]}
            >
              <Input.Password
                prefix={
                  <Icon.LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Mật khẩu"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Đăng ký
              </Button>
              Hoặc <Link to="/user">đăng nhập</Link>
            </Form.Item>
          </Form>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          Thang Loi Corp © {new Date().getFullYear()} | Developed by{" "}
          <a href="https://innoteq.vn" target="__blank">
            Innoteq Ltd
          </a>
        </Layout.Footer>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    isLogged: state.user.isLogged,
    userInfo: state.user.userInfo,
  }),
  {
    registerUser,
  }
)(Register);
