import React, { Component } from 'react';
import { Tabs } from 'antd';
import Layout from '../../layouts/admin';

// import Subsidiary from './config-components/subsidiary';
import Banner from './config-components/banner';
import Reward from './config-components/reward';
import Customer from './config-components/customer';
import Review from './config-components/review';
import Youtube from './config-components/youtube';

class ConfigHome extends Component {
  render() {
    return (
      <Layout location='/admin/config-home'>
        <Tabs defaultActiveKey="2" type="card" style={{ marginBottom: 0 }}>
          {/* <Tabs.TabPane tab="Logo công ty" key="2">
            <Subsidiary />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab="Banner trang chủ" key="3">
            <Banner />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Giải thưởng" key="4">
            <Reward />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Đối tác phân phối" key="5">
            <Customer />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nhận xét - Review" key="6">
            <Review />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Youtube video" key="7">
            <Youtube />
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

export default ConfigHome;
