import React, { Component, Fragment } from "react";
import { Input, Button, Table, Avatar, Divider, Form, Modal } from "antd";
import { connect } from "react-redux";

import {
  getLogoList,
  createLogo,
  updateLogo,
  deleteLogo,
} from "../../../actions";
import { Paper } from "../../../layouts/admin";
import Media from "../../../components/media";
import { API_URL } from "../../../utils/service";

class Reward extends Component {
  formRef = React.createRef();

  state = {
    showModal: false,
    modalType: "Tạo mới",
    showMedia: false,
  };

  componentDidMount() {
    this.props.getLogoList();
  }

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({ showModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType } = this.state;
    const { createLogo, updateLogo, deleteLogo } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      values.type = 3; // eslint-disable-line
      if (modalType === "Tạo mới") {
        createLogo(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateLogo(id, values);
      } else if (modalType === "Xoá") {
        deleteLogo(values._id);
      }
      this.setState({ showModal: false });
      this.formRef.current?.resetFields();
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Xoá");
  };

  render() {
    const { list } = this.props;
    const { showModal, modalType, showMedia } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Media
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={(file) => {
            this.formRef.current?.setFieldsValue({
              featuredImage: file.filename,
            });
            this.setState({ showMedia: false });
          }}
        />
        <Modal
          title={`${modalType} giải thưởng`}
          open={showModal}
          onOk={this.handleSubmit}
          forceRender
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết"
              name="link"
              rules={[{ required: true, message: "Vui lòng nhập liên kết!" }]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Hình ảnh"
              name="featuredImage"
              rules={[{ required: true, message: "Vui lòng chọn hình!" }]}
            >
              <Input
                onClick={() => this.setState({ showMedia: true })}
                disabled={modalType === "Xoá"}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: "calc(100vh - 230px)" }}
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Ảnh",
                dataIndex: "featuredImage",
                key: "featuredImage",
                width: 100,
                render: (data) => (
                  <Avatar
                    shape="square"
                    size={64}
                    icon="picture"
                    src={`${API_URL}/upload/THUMB-${data}`}
                  />
                ),
              },
              {
                title: "Đường dẫn",
                dataIndex: "link",
                key: "link",
                render: (text) => (
                  <a href={text} target="__blank">
                    {text}
                  </a>
                ),
              },
              {
                title: "Hành động",
                key: "action",
                width: 110,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    list: state.home.rewardList,
  }),
  {
    getLogoList,
    createLogo,
    updateLogo,
    deleteLogo,
  }
)(Reward);
