import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Pagination } from 'antd';
// import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import ArticlesSkeleton from '../components/articles-skeleton';
import ArticleCard, {
  ArticleName,
  ArticleMeta,
  ArticleDescription,
  ArticleImage,
} from '../components/article-card';
import { API_URL } from '../utils/service';
import media from '../utils/media';
import { getArticlesBySR } from '../queries/page';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getShareholderMeeting } from '../queries/shareholderMeeting';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
  min-height: 70vh;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #0b3a70;
  margin-left: 15px;
  margin-bottom: 20px;
`;

const BigImage = styled(ArticleImage)`
  height: 365px;
  ${media.tablet`
    height: 300px;
  `};
  ${media.phablet`
    height: 240px;
  `};
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'news.title' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

const ShareholderMeeting = ({srId}) => {
  const locale = useSelector(state => state.setting.locale)
  const [current, setCurrent] = useState(1);
  const {data: result, isFetching, error} = useQuery(
    ['getArticlesBySR', srId, current],
    () => getArticlesBySR(srId, current - 1),
    {select: data => data?.data || [], retry: false})
  const {data: category} = useQuery(
    ['getShareholderMeeting', srId],
    () => getShareholderMeeting(srId),
    {select: data => data?.data || {}}
  )
  const data = result?.articles || []
  const total = result?.total || 0

  return (
    <Layout locale={locale}>
      <Container>
        <InjectedPageBreadcumb />
        <Wrapper>
          <Title>
            {category?.name || <FormattedMessage id='news.title' />}
          </Title>
          {error && <p><FormattedMessage id='error' /></p>}
          <Grid fluid>
            {current === 1 && data && data?.length > 0 && <Row style={{ marginBottom: 20 }}>
              <Col xs={12} md={5} lg={7}>
                <BigImage image={`${API_URL}/upload/${data[0].featuredImage}`} />
              </Col>
              <Col xs={12} md={7} lg={5} style={{ position: 'relative' }}>
                <ArticleMeta style={{ fontSize: 14, marginTop: 10 }}>
                  {data[0].category.engName && locale === 'en' ? data[0].category.engName : data[0].category.name}
                </ArticleMeta>
                <ArticleName to={`/detail-article/${data[0]._id}`} style={{ fontSize: 20, lineHeight: '24px', maxHeight: 'unset', '-webkit-line-clamp': 'unset' }}>
                  {locale === 'en' && data[0].engTitle ? data[0].engTitle : data[0].title}
                </ArticleName>
                <ArticleDescription>{locale === 'en' && data[0].engExcerpt ? data[0].engExcerpt : data[0].excerpt}</ArticleDescription>
                {/* <ArticleMeta style={{ fontSize: 14, marginTop: 15 }}>
                  <FormattedMessage id='advisory.createdAt' /> {moment(data[0].createdAt).fromNow()}
                </ArticleMeta> */}
              </Col>
            </Row>}
            {isFetching && !data && !data.length ? (
              <ArticlesSkeleton />
            ) : (
              <Row>
                {current === 1 ? data?.map((item, idx) => (idx !== 0) && (
                  <Col key={item.title} xs={12} md={6} xl={4}>
                    <ArticleCard locale={locale} data={item} />
                  </Col>
                )) : data?.map((item) => (
                  <Col key={item.title} xs={12} md={6} xl={4}>
                    <ArticleCard locale={locale} data={item} />
                  </Col>
                ))}
              </Row>
            )}
            <Pagination
              total={total}
              defaultCurrent={1}
              current={current}
              pageSize={8}
              onChange={(page) => setCurrent(page)}
            />
          </Grid>
        </Wrapper>
      </Container>
    </Layout>
  );
}

export default ShareholderMeeting;
