import React, { Component } from 'react';
import {
  Input, Button, Table, Modal, Divider, Tag, Popconfirm, Select,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getOrderList,
  deleteOrder,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import DetailOrder from '../../components/detail-order';

export const status = [
  { code: 'new', title: 'Mới tạo', color: 'orange', hexColor: '#FA8C15' },
  { code: 'preparing', title: 'Đóng gói', color: 'blue', hexColor: '#1890FE' },
  { code: 'shipping', title: 'Đang vận chuyển', color: 'geekblue', hexColor: '#2E54EB' },
  { code: 'done', title: 'Hoàn thành', color: 'green', hexColor: '#52C41A' },
  { code: 'cancelled', title: 'Bị huỷ', color: 'red', hexColor: '#F5212D' },
];

const Option = Select.Option;

class OrderAll extends Component {
  state = {
    showModal: false,
    selectedRowKeys: [],
    selected: null,
    filter: 'all',
  }

  componentDidMount() {
    this.props.getOrderList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  hideModal = () => {
    this.setState({ showModal: false, selected: null });
  }

  handleDelete = record => {
    this.props.deleteOrder(record._id);
  }

  handleView = record => {
    this.setState({ showModal: true, selected: record });
  }

  render() {
    const { data } = this.props;
    const { showModal, selectedRowKeys, selected, filter } = this.state;
    const filteredData = filter === 'all' ? data : data.filter(item => item.currentStatus === filter);

    return (
      <Layout location='/admin/order-all'>
        <Modal
          centered
          title={<span>Xem đơn đặt hàng</span>}
          open={showModal}
          onOk={this.hideModal}
          forceRender
          onCancel={this.hideModal}
          footer={[
            <Button key="submit" type="primary" onClick={this.hideModal}>Xong</Button>,
          ]}
        >
          {selected && (
            <DetailOrder data={selected} />
          )}
        </Modal>
        <Paper
          title='Danh sách đơn đặt hàng'
          extra={<div>
            <Select
              onChange={value => this.setState({ filter: value })}
              value={filter}
              defaultValue='all'
              placeholder='Lọc trạng thái'
              style={{ width: 180 }}
            >
              <Option key='default' value='all'>
                <Tag>TẤT CẢ</Tag>
              </Option>
              {status.map(item =>
                (<Option key={item.title} value={item.code}>
                  <Tag color={item.color}>{item.title.toUpperCase()}</Tag>
                </Option>))}
            </Select>
            <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginLeft: 10 }} />
          </div>}
        >
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Trạng thái',
              key: 'currentStatus',
              dataIndex: 'currentStatus',
              render: text => (<Tag color={status.find(i => i.code === text) ? status.find(i => i.code === text).color : null}>
                {status.find(i => i.code === text)
                  ? status.find(i => i.code === text).title.toUpperCase()
                  : 'KHÔNG XÁC ĐỊNH'}
              </Tag>),
            }, {
              title: 'Khách hàng',
              key: 'receiver',
              render: record => <a>{record.receiver.fullname}</a>,
            }, {
              title: 'Phương thức',
              dataIndex: 'method',
              key: 'method',
              render: text => <Tag>{text.toUpperCase()}</Tag>,
            }, {
              title: 'Tổng thanh toán',
              dataIndex: 'totalPayment',
              key: 'totalPayment',
              render: text => <span>{text.toLocaleString()}₫</span>,
            }, {
              title: 'Thời gian',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: text => <span>{moment(text).fromNow()}</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              width: 120,
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleView(record)}>Xem</a>
                  <Divider type="vertical" />
                  <Popconfirm
                    placement="bottomRight"
                    title='Bạn có chắc chắn muốn xoá đơn hàng này?'
                    onConfirm={() => this.handleDelete(record)} okText="Đồng ý" cancelText="Huỷ"
                  >
                    <a>Xoá</a>
                  </Popconfirm>
                </span>
              ),
            }]}
            dataSource={filteredData}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.order.list,
}), {
  getOrderList,
  deleteOrder,
})(OrderAll);
