import { message } from 'antd';

import { query, ENDPOINTS } from '../utils/service';

export const GET_HOME_REQUEST = 'get-home-request';
export const GET_HOME_SUCCESS = 'get-home-success';
export const GET_HOME_FAILURE = 'get-home-failure';

export const GET_PRODUCT_PAGE_REQUEST = 'get-product-page-request';
export const GET_PRODUCT_PAGE_SUCCESS = 'get-product-page-success';
export const GET_PRODUCT_PAGE_FAILURE = 'get-product-page-failure';

export const GET_BEST_SELLER_PAGE_REQUEST = 'get-best-seller-page-request';
export const GET_BEST_SELLER_PAGE_SUCCESS = 'get-best-seller-page-success';
export const GET_BEST_SELLER_PAGE_FAILURE = 'get-best-seller-page-failure';

export const GET_FEATURED_PAGE_REQUEST = 'get-featured-page-request';
export const GET_FEATURED_PAGE_SUCCESS = 'get-featured-page-success';
export const GET_FEATURED_PAGE_FAILURE = 'get-featured-page-failure';

export const GET_ARTICLE_PAGE_REQUEST = 'get-article-page-request';
export const GET_ARTICLE_PAGE_SUCCESS = 'get-article-page-success';
export const GET_ARTICLE_PAGE_FAILURE = 'get-article-page-failure';

export const GET_ADVISORY_PAGE_REQUEST = 'get-advisory-page-request';
export const GET_ADVISORY_PAGE_SUCCESS = 'get-advisory-page-success';
export const GET_ADVISORY_PAGE_FAILURE = 'get-advisory-page-failure';

export const GET_PRODUCT_DETAIL_REQUEST = 'get-product-detail-request';
export const GET_PRODUCT_DETAIL_SUCCESS = 'get-product-detail-success';
export const GET_PRODUCT_DETAIL_FAILURE = 'get-product-detail-failure';

export const GET_PRODUCT_BY_CATEGORY_REQUEST = 'get-product-by-category-request';
export const GET_PRODUCT_BY_CATEGORY_SUCCESS = 'get-product-by-category-success';
export const GET_PRODUCT_BY_CATEGORY_FAILURE = 'get-product-by-category-failure';

export const GET_PRODUCT_BY_SECTION_REQUEST = 'get-product-by-section-request';
export const GET_PRODUCT_BY_SECTION_SUCCESS = 'get-product-by-section-success';
export const GET_PRODUCT_BY_SECTION_FAILURE = 'get-product-by-section-failure';

export const GET_PRODUCT_BY_SUBCATEGORY_REQUEST = 'get-product-by-subcategory-request';
export const GET_PRODUCT_BY_SUBCATEGORY_SUCCESS = 'get-product-by-subcategory-success';
export const GET_PRODUCT_BY_SUBCATEGORY_FAILURE = 'get-product-by-subcategory-failure';

export const GET_ARTICLE_DETAIL_REQUEST = 'get-article-detail-request';
export const GET_ARTICLE_DETAIL_SUCCESS = 'get-article-detail-success';
export const GET_ARTICLE_DETAIL_FAILURE = 'get-article-detail-failure';

export function getHome() {
  return async dispatch => {
    dispatch({ type: GET_HOME_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getHome,
      });
      if (result.status === 200) {
        dispatch({ type: GET_HOME_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_HOME_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_HOME_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductPage() {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_PAGE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProduct,
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_PAGE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_PAGE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_PAGE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getBestSellerPage() {
  return async dispatch => {
    dispatch({ type: GET_BEST_SELLER_PAGE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: { isBestSeller: true },
      });
      if (result.status === 200) {
        dispatch({ type: GET_BEST_SELLER_PAGE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_BEST_SELLER_PAGE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_BEST_SELLER_PAGE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getFeaturedPage() {
  return async dispatch => {
    dispatch({ type: GET_FEATURED_PAGE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: { isFeatured: true },
      });
      if (result.status === 200) {
        dispatch({ type: GET_FEATURED_PAGE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_FEATURED_PAGE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_FEATURED_PAGE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

const NEWS_CATEGORY = '5c303acd32dc580023f08f13';

export function getArticlePage(page) {
  return async dispatch => {
    dispatch({ type: GET_ARTICLE_PAGE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getArticleList,
        params: {
          category: NEWS_CATEGORY,
          limit: (!page || page === 0) ? 10 : 9,
          skip: (!page || page === 0) ? 0 : (page * 9) + 1,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_ARTICLE_PAGE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ARTICLE_PAGE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_ARTICLE_PAGE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

const ADVISORY_CATEGORY = '5c40005cc8171444aa1f1b06';

export function getAdvisoryPage(page) {
  return async dispatch => {
    dispatch({ type: GET_ADVISORY_PAGE_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getArticleList,
        params: {
          category: ADVISORY_CATEGORY,
          limit: (!page || page === 0) ? 10 : 9,
          skip: (!page || page === 0) ? 0 : (page * 9) + 1,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_ADVISORY_PAGE_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ADVISORY_PAGE_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_ADVISORY_PAGE_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductByCategory(id) {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_BY_CATEGORY_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: {
          category: id,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_BY_CATEGORY_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_BY_CATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_BY_CATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductBySection(id) {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_BY_SECTION_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: {
          homeSection: id,
          limit: 1000
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_BY_SECTION_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_BY_SECTION_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_BY_SECTION_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductBySubcategory(id) {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_BY_SUBCATEGORY_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductList,
        params: {
          subcategory: id,
        },
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_BY_SUBCATEGORY_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_BY_SUBCATEGORY_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_BY_SUBCATEGORY_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getProductDetail(id) {
  return async dispatch => {
    dispatch({ type: GET_PRODUCT_DETAIL_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getProductDetail + id,
      });
      if (result.status === 200) {
        dispatch({ type: GET_PRODUCT_DETAIL_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_PRODUCT_DETAIL_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_PRODUCT_DETAIL_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}

export function getArticleDetail(id) {
  return async dispatch => {
    dispatch({ type: GET_ARTICLE_DETAIL_REQUEST });
    try {
      const result = await query({
        endpoint: ENDPOINTS.getArticleDetail + id,
      });
      if (result.status === 200) {
        dispatch({ type: GET_ARTICLE_DETAIL_SUCCESS, payload: result.data });
      } else {
        dispatch({ type: GET_ARTICLE_DETAIL_FAILURE });
        message.error('Có lỗi xảy ra');
      }
    } catch (error) {
      dispatch({ type: GET_ARTICLE_DETAIL_FAILURE });
      message.error('Có lỗi xảy ra');
    }
  };
}
