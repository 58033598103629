import React, { useState } from 'react';
import {
  Input,
  Button,
  Table,
  Divider,
  Form,
  Modal,
} from 'antd';

import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createShareholderMeeting, deleteShareholderMeeting, getShareholderMeetingList, updateShareholderMeeting } from '../../queries/shareholderMeeting';

const ShareholderMeeting = () => {
  const [form] = Form.useForm();
  const { data, isFetching } = useQuery('shareholderMeeting', getShareholderMeetingList, {
    select: data => data ? data.data : []
  })
  const queryClient = useQueryClient()
  const { mutateAsync: createShareholderMeetingAsync, isLoading: isCreating } = useMutation((values) => createShareholderMeeting(values))
  const { mutateAsync: updateShareholderMeetingAsync, isLoading: isUpdating } = useMutation(({id, values}) => updateShareholderMeeting(id, values))
  const { mutateAsync: deleteShareholderMeetingAsync, isLoading: isDeleting } = useMutation((id) => deleteShareholderMeeting(id))

  const [{ showMedia, showModal, modalType, selectedRowKeys }, setState] = useState(
    {
      showMedia: false,
      showModal: false,
      modalType: 'Tạo mới',
      selectedRowKeys: [],
    }
  );

  const onSelectChange = (selectedRowKeys) => {
    setState({ selectedRowKeys });
  }

  const setShowModal = modalType => setState({
    showModal: true,
    modalType,
  })

  const hideModal = () => {
    setState({ showModal: false });
    form.resetFields();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then(async values => {
      console.log(values)
      if (modalType === 'Tạo mới') {
        await createShareholderMeetingAsync(values);
      } else if (modalType === 'Chỉnh sửa') {
        const id = values._id;
        delete values._id; // eslint-disable-line
        await updateShareholderMeetingAsync({id, values});
      } else if (modalType === 'Xoá') {
        await deleteShareholderMeetingAsync(values._id);
      }
      queryClient.invalidateQueries('shareholderMeeting')
      setState({ showModal: false });
      form.resetFields();
    });
  }

  const handleEdit = record => {
    form.setFieldsValue(record);
    setShowModal('Chỉnh sửa');
  }

  const handleDelete = record => {
    form.setFieldsValue(record);
    setShowModal('Xoá');
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Layout location='/admin/shareholder-meeting'>
      <Media
        visible={showMedia}
        onCancel={() => setState({ showMedia: false })}
        onSubmit={file => {
          form.setFieldsValue({ featuredImage: file.filename });
          setState({ showMedia: false });
        }}
      />
      <Modal
        title={`${modalType}`}
        open={showModal}
        onOk={handleSubmit}
        forceRender
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>Huỷ</Button>,
          <Button loading={isCreating || isUpdating || isDeleting} key="submit" type="primary" onClick={handleSubmit}>Xác nhận</Button>,
        ]}
      >
        <Form onSubmit={handleSubmit} form={form}>
          <Form.Item noStyle name='_id'>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Tên đại hội"
            name='name'
            rules={[{
              required: true, message: 'Vui lòng nhập tên đại hội!',
            }]}
          >
            <Input disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Tên đại hội (Tiếng Anh)"
            name='engName'
          >
            <Input disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Năm"
            name='year'
            rules={[{
              required: true, message: 'Vui lòng chọn năm!',
            }]}
          >
            <Input placeholder={new Date().getFullYear()} disabled={modalType === 'Xoá'} />
          </Form.Item>
        </Form>
      </Modal>
      <Paper
        title='Đại hội Cổ Đông'
        extra={<div>
          <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
          <Button onClick={() => setShowModal('Tạo mới')} type='primary'>Tạo mới</Button>
        </div>}
      >
        <Table
          loading={isFetching}
          bordered
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={[{
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
          }, {
            title: 'Năm',
            dataIndex: ['year'],
            key: 'year',
          }, {
            title: 'Người tạo',
            dataIndex: ['createdBy', 'fullname'],
            key: 'createdBy',
            render: data => <a>{data}</a>,
          }, {
            title: 'Hành động',
            key: 'action',
            width: 120,
            render: (record) => (
              <span>
                <a role='button' onClick={() => handleEdit(record)}>Sửa</a>
                <Divider type="vertical" />
                <a role='button' onClick={() => handleDelete(record)}>Xoá</a>
              </span>
            ),
          }]}
          dataSource={data || []}
        />
      </Paper>
    </Layout>
  );
}

export default ShareholderMeeting