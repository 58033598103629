import React, { Component } from 'react';
import { Button, Input, Card, Pagination, Popconfirm, Spin } from 'antd';
import * as Icon from '@ant-design/icons';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import moment from 'moment';

import { getFileList, uploadFile, deleteFile } from '../../actions';

import Layout, { Paper } from '../../layouts/admin';
import { API_URL } from '../../utils/service';

class Media extends Component {
  state = { selected: [], search: '', uploading: false, current: 1 };

  componentDidMount() {
    this.props.getFileList({ skip: 0, search: this.state.search });
  }

  handleUpload = (e) => {
    this.props.uploadFile(e.target.files[0]);
  }

  handleSelectPage = page => {
    this.setState({ current: page });
    this.props.getFileList({ skip: page - 1, search: this.state.search });
  }

  handleDelete = () => {
    this.state.selected.forEach(item => this.props.deleteFile(item._id));
    this.setState({ selected: [] });
  }

  handleSearch = () => {
    const { search } = this.state;
    this.props.getFileList({ skip: 0, search });
  }

  render() {
    const { selected, search, current } = this.state;
    const { list, loading, onCancel, onSubmit, visible, title, deleteFile, total, ...props } = this.props; // eslint-disable-line

    return (
      <Layout location='/admin/media'>
        <Paper
          title='Thư viện hình ảnh'
          extra={<div>
            <Input.Search
              value={search}
              onChange={e => this.setState({ search: e.target.value })}
              style={{ width: 200, marginRight: 10 }}
              onSearch={this.handleSearch}
            />
            <input
              type='file'
                ref={fileInput => this.fileInput = fileInput} // eslint-disable-line
              onChange={this.handleUpload}
              style={{ display: 'none' }}
              accept='.png,.jpg'
            />
            {selected.length > 0 && (
              <Popconfirm
                title={`Bạn muốn xoá ${selected.length} hình đã chọn?`}
                onConfirm={this.handleDelete}
                okText='Đồng ý'
                cancelText='Huỷ'
                placement='bottomLeft'
              >
                <Button style={{ float: 'right', marginLeft: 10 }} type='danger'>Xoá</Button>
              </Popconfirm>
            )}
            <Button type='primary' onClick={() => this.fileInput.click()}><Icon.UploadOutlined />Tải lên</Button>
          </div>}
        >
          {loading && <Spin tip='Loading...' />}
          <Row style={{ position: 'relative' }}>
            <div
              role='button'
              style={{ position: 'absolute', width: '100%', height: '100%' }}
              onClick={() => this.setState({ selected: [] })}
            />
            {list.map(item => {
              const isSelected = selected.length > 0 && selected.findIndex(i => i._id === item._id) >= 0;
              return (
                <Col lg={3} key={item._id} style={{ marginBottom: 15 }}>
                  <Card
                    hoverable
                    bordered={false}
                    bodyStyle={{ padding: 10 }}
                    style={{ border: isSelected ? '2px solid #1890FE' : '2px solid #e8e8e8' }}
                    onClick={() => (isSelected
                      ? this.setState({ selected: selected.filter(i => i._id !== item._id) })
                      : this.setState({ selected: [...selected, item] }))}
                    cover={
                      <img
                        style={{ height: 170, objectFit: 'cover' }}
                        alt={item.originalname}
                        src={`${API_URL}/upload/THUMB-${item.filename}`}
                      />
                    }
                  >
                    <Card.Meta
                      title={item.originalname}
                      description={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`${Math.round(item.size / 1024)} KB`}</span>
                        <span>{`${moment(item.createdAt).fromNow()}`}</span>
                      </div>}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Pagination
            total={total}
            defaultCurrent={1}
            current={current}
            pageSize={8}
            onChange={this.handleSelectPage}
            showTotal={sum => <span>Tổng số: {sum}</span>}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(state => ({
  list: state.upload.list,
  total: state.upload.total,
  loading: state.upload.loading,
}), {
  uploadFile,
  getFileList,
  deleteFile,
})(Media);
