import React, { Component } from "react";
import { Input, Button, Table, Divider, Form, Modal, Tabs } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import {
  getStoryList,
  createStory,
  updateStory,
  deleteStory,
} from "../../actions";
import Layout, { Paper } from "../../layouts/admin";

import Editor from "../../components/editor";

class Story extends Component {
  formRef = React.createRef();

  state = {
    showModal: false,
    modalType: "Tạo mới",
    selectedRowKeys: [],
    editorContent: "",
    editorContentEng: "",
  };

  componentDidMount() {
    this.props.getStoryList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({ showModal: false, editorContent: "" });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType, editorContent, editorContentEng } = this.state;
    const { createStory, updateStory, deleteStory } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      values.content = editorContent; // eslint-disable-line
      values.engContent = editorContentEng; // eslint-disable-line
      if (modalType === "Tạo mới") {
        createStory(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateStory(id, values);
      } else if (modalType === "Xoá") {
        deleteStory(values._id);
      }
      this.setState({
        showModal: false,
        editorContent: "",
        editorContentEng: "",
      });
      this.formRef.current?.resetFields();
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.setState({
      editorContent: record.content,
      editorContentEng: record.engContent,
    });
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.setState({
      editorContent: record.content,
      editorContentEng: record.engContent,
    });
    this.showModal("Xoá");
  };

  render() {
    const { list } = this.props;
    const {
      showModal,
      modalType,
      selectedRowKeys,
      editorContent,
      editorContentEng,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <Layout location="/admin/config-story">
        <Modal
          width="70%"
          style={{ maxHeight: "80vh" }}
          forceRender
          title={`${modalType} câu chuyện`}
          open={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên câu chuyện!",
                },
              ]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tiêu đề (Tiếng Anh)"
              name="engName"
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item {...formItemLayout} label="Nội dung">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane key="1" tab="Tiếng Việt (*)">
                  <Editor
                    value={editorContent}
                    onChange={(content) =>
                      this.setState({ editorContent: content })
                    }
                    readOnly={modalType === "Xoá"}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Tiếng Anh">
                  <Editor
                    value={editorContentEng}
                    onChange={(content) =>
                      this.setState({ editorContentEng: content })
                    }
                    readOnly={modalType === "Xoá"}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          title="Danh sách câu chuyện"
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[
              {
                title: "Tên",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Người đăng",
                dataIndex: "createdBy.fullname",
                key: "createdBy",
                render: (data) => <a>{data}</a>,
              },
              {
                title: "Ngày đăng",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (data) => <span>{moment(data).fromNow()}</span>,
              },
              {
                title: "Hành động",
                key: "action",
                width: 110,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={list}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    list: state.story.list,
  }),
  {
    getStoryList,
    createStory,
    updateStory,
    deleteStory,
  }
)(Story);
