import {
  GET_FILE_LIST_FAILURE,
  GET_FILE_LIST_REQUEST,
  GET_FILE_LIST_SUCCESS,
  // UPLOAD_FILE_FAILURE,
  // UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  // DELETE_FILE_FAILURE,
  // DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  total: 0,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FILE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FILE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.uploads,
        total: action.payload.total,
        loading: false,
      };
    case GET_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
        total: state.total + 1,
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        list: state.list.filter(item => item._id !== action.payload._id),
      };
    default:
      return state;
  }
};
