import React, { Component, Fragment } from "react";
import { Input, Button, Table, Avatar, Divider, Form, Modal } from "antd";
import { connect } from "react-redux";

import {
  getReviewList,
  createReview,
  updateReview,
  deleteReview,
} from "../../../actions";
import { Paper } from "../../../layouts/admin";
import Media from "../../../components/media";
import { API_URL } from "../../../utils/service";

class Review extends Component {
  formRef = React.createRef();

  state = {
    showModal: false,
    modalType: "Tạo mới",
    showMedia: false,
  };

  componentDidMount() {
    this.props.getReviewList();
  }

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({ showModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType } = this.state;
    const { createReview, updateReview, deleteReview } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      if (modalType === "Tạo mới") {
        createReview(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateReview(id, values);
      } else if (modalType === "Xoá") {
        deleteReview(values._id);
      }
      this.setState({ showModal: false });
      this.formRef.current?.resetFields();
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Xoá");
  };

  render() {
    const { list } = this.props;
    const { showModal, modalType, showMedia } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Media
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={(file) => {
            this.formRef.current?.setFieldsValue({ background: file.filename });
            this.setState({ showMedia: false });
          }}
        />
        <Modal
          title={`${modalType} nhận xét khách hàng`}
          open={showModal}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          forceRender
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên khách hàng"
              name="write"
              rules={[
                { required: true, message: "Vui lòng nhập tên khách hàng!" },
              ]}
            >
              <Input multiple disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Nội dung"
              name="content"
              rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
            >
              <Input.TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                disabled={modalType === "Xoá"}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Nội dung (Tiếng Anh)"
              name="engContent"
            >
              <Input.TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                disabled={modalType === "Xoá"}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Ảnh nền"
              name="background"
              rules={[{ required: true, message: "Vui lòng chọn hình nền!" }]}
            >
              <Input
                onClick={() => this.setState({ showMedia: true })}
                disabled={modalType === "Xoá"}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: "calc(100vh - 230px)" }}
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Ảnh nền",
                dataIndex: "background",
                key: "background",
                width: 100,
                render: (data) => (
                  <Avatar
                    shape="square"
                    size={64}
                    icon="picture"
                    src={`${API_URL}/upload/THUMB-${data}`}
                  />
                ),
              },
              {
                title: "Tên khách hàng",
                dataIndex: "writer",
                key: "writer",
              },
              {
                title: "Nội dung",
                dataIndex: "content",
                key: "content",
              },
              {
                title: "Hành động",
                key: "action",
                width: 110,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    list: state.home.reviewList,
  }),
  {
    getReviewList,
    createReview,
    updateReview,
    deleteReview,
  }
)(Review);
