import React from 'react';
import Sticky from 'react-sticky-el';
// import Subsidiary from './subsidiary';
import Navigation from './navigation';
import Menu from './menu';

const Header = ({ topMenus, productMenus, locale }) => (
  <div style={{ zIndex: 1, position: 'relative' }}>
    {/* <Subsidiary /> */}
    <Sticky>
      <Navigation locale={locale} menu={topMenus} />
      <Menu locale={locale} data={productMenus} />
    </Sticky>
  </div>
);

export default Header;
