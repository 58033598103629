import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/vi';
import { IntlProvider, addLocaleData } from 'react-intl';
import enData from 'react-intl/locale-data/en';
import viData from 'react-intl/locale-data/vi';

import Header from '../components/header';
import Footer from '../components/footer';
import Social from '../components/social';
import Messenger from '../components/messenger';
import './layout.css';
import { getLayout } from '../actions';

import en from '../i18n/en.json';
import vi from '../i18n/vi.json';
import Loading from '../components/loading';

const messages = { en, vi };

addLocaleData([...enData, ...viData]);

moment.locale('vi');

class Layout extends Component {
  componentDidMount() {
    this.props.getLayout();
  }

  render() {
    const { children, topMenus, socialLinks, footerLinks, productMenus, showSocial, locale, contacts, loading, initLayout } = this.props;
    if (!initLayout && loading) {
      return <Loading />;
    }

    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div>
          <Helmet
            title='Thắng Lợi'
            meta={[
              { name: 'description', content: 'Website chính thức của Công ty CP May Quốc Tế Thắng Lợi' },
              { name: 'keywords', content: 'thangloi, May Thắng Lợi, hometex' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header locale={locale} topMenus={topMenus} productMenus={productMenus} />
          <div>
            {children}
          </div>
          {showSocial && <Social data={socialLinks} />}
          <Footer locale={locale} contacts={contacts} data={footerLinks} />
          <Messenger />
        </div>
      </IntlProvider>
    );
  }
}

export default connect(state => ({
  topMenus: state.layout.topMenus,
  productMenus: state.layout.productMenus,
  socialLinks: state.layout.socialLinks,
  footerLinks: state.layout.footerLinks,
  contacts: state.layout.contacts,
  loading: state.layout.loading,
  initLayout: state.layout.initLayout,
  locale: state.setting.locale,
}), {
  getLayout,
})(Layout);
