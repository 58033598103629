import React, { Component } from 'react';
import styled from 'styled-components';
import { Select, Tag, List, Avatar, Skeleton, Tabs, Timeline, Modal, Input, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { status } from '../pages/admin/order-all';
import { API_URL } from '../utils/service';
import { getOrderDetail, updateStatus } from '../actions';

const getImage = image => `${API_URL}/upload/${image}`;

const Option = Select.Option;
const TabPane = Tabs.TabPane;

const Info = styled.ul`
  list-style: none;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;

class DetailOrder extends Component {
  state = { showNoteModal: false, note: '', newStatus: '' }

  componentDidMount = () => {
    this.props.getOrderDetail(this.props.data._id);
  }

  handleUpdateStatus = () => {
    const { data, updateStatus } = this.props; // eslint-disable-line
    const { note, newStatus } = this.state;
    updateStatus(data._id, { code: newStatus, note });
    this.hideNoteModal();
  }

  hideNoteModal = () => this.setState({ showNoteModal: false, note: '', newStatus: '' })
  showNoteModal = value => this.setState({ showNoteModal: true, newStatus: value })

  render() {
    const { data, detail, loading, readOnly } = this.props;
    const { showNoteModal, newStatus, note } = this.state;
    return (
      <Info>
        {!loading && detail && <Modal
          centered
          title='Cập nhật trạng thái đơn hàng'
          open={showNoteModal}
          onOk={this.hideNoteModal}
          forceRender
          onCancel={this.hideNoteModal}
          footer={[
            <Button key="cancel" onClick={this.hideNoteModal}>Huỷ</Button>,
            <Button key="submit" type="primary" onClick={this.handleUpdateStatus}>Xác nhận</Button>,
          ]}
        >
          <p>
            Trạng thái hiện tại: <Tag color={status.find(i => i.code === detail.currentStatus).color}>
              {status.find(i => i.code === detail.currentStatus).title.toUpperCase()}
            </Tag>
          </p>
          <p>
            Trạng thái mới: {status.find(i => i.code === newStatus) && (
              <Tag color={status.find(i => i.code === newStatus).color}>
                {status.find(i => i.code === newStatus).title.toUpperCase()}
              </Tag>
            )}
          </p>
          <Input
            value={note}
            onChange={e => this.setState({ note: e.target.value })}
            placeholder='Ghi chú thêm (không bắt buộc)'
          />
        </Modal>}
        <li>Trạng thái:
          {readOnly
            ? (
              <Tag color={status.find(i => i.code === data.currentStatus).color}>
                {status.find(i => i.code === data.currentStatus).title.toUpperCase()}
              </Tag>
            )
            : (
              <Select
                onChange={value => this.showNoteModal(value)}
                defaultValue={data.currentStatus}
                disabled={loading}
                style={{ width: 180 }}
              >
                {status.map(item =>
                  (<Option key={item.title} value={item.code}>
                    <Tag color={item.color}>{item.title.toUpperCase()}</Tag>
                  </Option>))}
              </Select>
            )
          }
        </li>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Chi tiết đơn' key='1' style={{ paddingTop: 16 }}>
            <h3 style={{marginBottom: 8}}>Thông tin khách hàng</h3>
            <li>Họ và tên: <span>{data.receiver.fullname}</span></li>
            <li>Số điện thoại: <a href={`tel:${data.receiver.phone}`} target='__blank'>{data.receiver.phone}</a></li>
            {data.receiver.email && <li>Email: <a href={`tel:${data.receiver.email}`} target='__blank'>{data.receiver.email}</a></li>}
            <li>Địa chỉ: <span>{data.receiver.address}</span></li>
            <h3>Đơn hàng ({data.data.length} sản phẩm)</h3>
            {(!loading && detail) ? <List
              itemLayout="vertical"
              size="large"
              style={{ maxHeight: 350, overflow: 'scroll' }}
              dataSource={detail.data}
              renderItem={(item, idx) => {
                const image = item.product.images.find(i => i.color === item.color)
                  ? getImage(item.product.images.find(i => i.color === item.color).image)
                  : getImage(item.product.images[0].image);
                return (
                  <List.Item key={idx} style={{ marginBottom: 0 }}>
                    <List.Item.Meta
                      avatar={<Avatar size={150} shape='square' src={image} />}
                      title={<a href='#'>{`${item.product.name}`}</a>}
                      style={{ marginBottom: 0 }}
                      description={
                        <ul style={{ listStyle: 'square' }}>
                          <li style={{ marginBottom: 0 }}>Kích thước: <strong>{item.size}</strong></li>
                          <li style={{ marginBottom: 0 }}>Màu sắc: <strong>{item.color}</strong></li>
                          <li style={{ marginBottom: 0 }}>Số lượng: <strong>{item.quantity}</strong></li>
                          <li style={{ marginBottom: 0 }}>Thành tiền: <strong>{`${item.price.toLocaleString()}₫`}</strong></li>
                        </ul>
                      }
                    />
                    {item.content}
                  </List.Item>
                );
              }}
            /> : <List
              itemLayout="vertical"
              size="large"
              style={{ maxHeight: 350, overflow: 'scroll' }}
              dataSource={[{}, {}]}
              renderItem={(item, idx) => (
                <List.Item key={idx} style={{ marginBottom: 0 }}>
                  <Skeleton loading={loading} active avatar>
                    <List.Item.Meta />
                  </Skeleton>
                </List.Item>
              )}
            />}
            <h3 style={{marginBottom: 8}}>Phương thức thanh toán</h3>
            <Tag style={{textTransform: 'uppercase'}} color={data.method === 'cod' ? 'blue' : 'pink'}>
              {{
                'cod': 'Thanh toán khi nhận hàng',
                'bank': 'Chuyển khoản ngân hàng',
              }[data.method]}
            </Tag>
          </TabPane>
          <TabPane key='2' tab='Lịch sử' style={{ paddingTop: 16 }}>
            {(!loading && detail) && (
              detail.status.map(item => (
                <Timeline.Item key={item._id} color={status.find(i => i.code === item.code).hexColor}>
                  <a>{item.creator ? item.creator.fullname : 'Guest'}</a> đã đổi trạng thái thành&nbsp;
                  <Tag color={status.find(i => i.code === item.code).color}>
                    {status.find(i => i.code === item.code).title.toUpperCase()}
                  </Tag>
                  <p><i>{moment(item.updatedAt).fromNow()}</i></p>
                  {item.note && <p>Ghi chú: {item.note}</p>}
                </Timeline.Item>
              ))
            )}
          </TabPane>
        </Tabs>
      </Info>
    );
  }
}

export default connect(state => ({
  detail: state.order.detail,
  loading: state.order.detailFetching,
}), {
  getOrderDetail,
  updateStatus,
})(DetailOrder);
