import React, { Component } from "react";
import {
  Input,
  Button,
  Table,
  Divider,
  Form,
  Modal,
  TreeSelect,
  Select,
  Tabs,
  Switch,
  Image,
} from "antd";
import * as Icon from "@ant-design/icons";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import {
  getProductList,
  getProductCategoryList,
  getProductSubcategoryList,
  createProduct,
  updateProduct,
  deleteProduct,
  searchAdminProduct,
  prioritizeProduct,
  getHomeSections,
} from "../../actions";
import Layout, { Paper } from "../../layouts/admin";
import Media from "../../components/product-media";
import Editor from "../../components/editor";
import { API_URL } from "../../utils/service";

const defaultColors = ["Đỏ", "Cam", "Vàng", "Lục", "Lam", "Chàm", "Tím"];

const UploadList = styled.div`
  float: left;
`;

const UploadItem = styled.div`
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  p {
    position: absolute;
    text-align: center;
    top: 32px;
    color: white;
  }
`;

class Product extends Component {
  formRef = React.createRef();

  state = {
    showMedia: false,
    showModal: false,
    modalType: "Tạo mới",
    showSizeModal: false,
    sizeModalType: "Tạo mới",
    selectedRowKeys: [],
    editorContent: "",
    editorContentEng: "",
    images: [],
    sizes: [],
    sizeFields: {},
    current: 1,
    search: "",
    filters: [],
    sorter: [],
  };

  componentDidMount() {
    const { filters, sorter } = this.state;
    this.props.getProductList({ skip: 0, filters, sorter });
    this.props.getProductCategoryList();
    this.props.getProductSubcategoryList();
    this.props.getHomeSections();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sorterOption = sorter.columnKey
      ? [sorter.columnKey, sorter.order]
      : null;
    this.setState({
      current: pagination.current,
      filters,
      sorter: sorterOption,
    });
    if (this.state.search) {
      this.props.searchAdminProduct({
        search: this.state.search,
        skip: pagination.current - 1,
        filters,
        sorter: sorterOption,
      });
    } else {
      this.props.getProductList({
        skip: pagination.current - 1,
        filters,
        sorter: sorterOption,
      });
    }
  };

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({
      showModal: false,
      images: [],
      editorContent: "",
      editorContentEng: "",
    });
    this.formRef.current?.resetFields();
  };

  resetField = () => {
    this.setState({
      showModal: false,
      sizes: [],
      images: [],
      editorContent: "",
      editorContentEng: "",
      sizeFields: {},
    });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType, editorContent, images, sizes, editorContentEng } =
      this.state;
    const { createProduct, updateProduct, deleteProduct, subcategories } =
      this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      values.content = editorContent; // eslint-disable-line
      values.engContent = editorContentEng; // eslint-disable-line
      values.images = images; // eslint-disable-line
      values.sizes = sizes; // eslint-disable-line
      const subcategory = subcategories.find(
        (item) => item._id === values.subcategory
      );
      if (subcategory) {
        values.category = subcategory.category._id; // eslint-disable-line
      }
      if (modalType === "Tạo mới") {
        createProduct(values, this.resetField);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateProduct(id, values, this.resetField);
      } else if (modalType === "Xoá") {
        deleteProduct(values._id, this.resetField);
      }
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue({
      ...record,
      subcategory: record.subcategory._id,
    });
    this.setState({
      editorContent: record.content,
      editorContentEng: record.engContent,
      images: record.images,
      sizes: record.sizes,
    });
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue({
      ...record,
      subcategory: record.subcategory._id,
    });
    this.setState({
      editorContent: record.content,
      editorContentEng: record.engContent,
      images: record.images,
      sizes: record.sizes,
    });
    this.showModal("Xoá");
  };

  handleSearch = () => {
    const { search, filters, sorter } = this.state;
    if (search) {
      this.props.searchAdminProduct({ search: search, skip: 0, sorter });
    } else {
      this.props.getProductList({ skip: 0, filters, sorter });
    }
  };

  handleUp = (record) => {
    const { filters, sorter } = this.state;
    this.props.prioritizeProduct(record._id, () =>
      this.props.getProductList({ skip: 0, filters, sorter })
    );
  };

  handleSubmitSize = () => {
    const { sizeModalType, sizes, sizeFields } = this.state;

    if (sizeModalType === "Tạo mới") {
      this.setState({
        sizes: [...sizes, sizeFields],
        sizeFields: {},
        showSizeModal: false,
      });
    } else if (sizeModalType === "Chỉnh sửa") {
      this.setState({
        sizes: sizes.map((size) =>
          size.name === sizeFields.name ? sizeFields : size
        ),
        sizeFields: {},
        showSizeModal: false,
      });
    } else {
      this.setState({
        sizes: sizes.filter((size) => size.name !== sizeFields.name),
        sizeFields: {},
        showSizeModal: false,
      });
    }
  };

  render() {
    const {
      products,
      loading,
      categories,
      subcategories,
      total,
      homeSections,
    } = this.props;
    const {
      showMedia,
      showModal,
      modalType,
      selectedRowKeys,
      editorContent,
      editorContentEng,
      images,
      sizes,
      sizeModalType,
      search,
      showSizeModal,
      sizeFields,
      current,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const categoryTree = categories.map((item) => ({
      title: item.name,
      value: item._id,
      key: item._id,
      selectable: false,
      children: subcategories
        .filter((sub) => sub.category._id === item._id)
        .map((sub) => ({
          title: sub.name,
          value: sub._id,
          key: sub._id,
        })),
    }));

    const homeSectionTree = homeSections.map((item) => ({
      title: item.label,
      value: item.key,
      key: item.key,
      selectable: !item.items || item.items.length === 0,
      children:
        item.items &&
        item.items.map((i) => ({
          title: i.label,
          value: i.key,
          key: i.key,
        })),
    }));

    return (
      <Layout location="/admin/product">
        <Media
          colors={this.formRef.current?.getFieldValue("colors")}
          visible={showMedia}
          onCancel={() => this.setState({ showMedia: false })}
          onSubmit={(data) => this.setState({ showMedia: false, images: data })}
        />
        <Modal
          title={`${modalType} kích cỡ`}
          open={showSizeModal}
          forceRender
          centered
          zIndex={1000}
          okOk={this.handleSubmitSize}
          onCancel={() =>
            this.setState({ sizeFields: {}, showSizeModal: false })
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({ sizeFields: {}, showSizeModal: false })
              }
            >
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmitSize}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmitSize} name="size">
            <Form.Item {...formItemLayout} label="Kích cỡ (*)">
              <Input
                disabled={sizeModalType === "Xoá"}
                value={sizeFields.name}
                onChange={(e) =>
                  this.setState({
                    sizeFields: { ...sizeFields, name: e.target.value },
                  })
                }
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label="Mã sản phẩm">
              <Input
                disabled={sizeModalType === "Xoá"}
                value={sizeFields.code}
                onChange={(e) =>
                  this.setState({
                    sizeFields: { ...sizeFields, code: e.target.value },
                  })
                }
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label="Giá gốc (*)">
              <Input
                disabled={sizeModalType === "Xoá"}
                value={sizeFields.price}
                onChange={(e) =>
                  this.setState({
                    sizeFields: { ...sizeFields, price: e.target.value },
                  })
                }
              />
            </Form.Item>
            <Form.Item {...formItemLayout} label="Giá khuyến mãi">
              <Input
                disabled={sizeModalType === "Xoá"}
                value={sizeFields.salePrice}
                onChange={(e) =>
                  this.setState({
                    sizeFields: { ...sizeFields, salePrice: e.target.value },
                  })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          width="70%"
          title={`${modalType} sản phẩm`}
          open={showModal}
          zIndex={500}
          forceRender
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} name="form" ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Thông tin cơ bản" key="1">
                <Form.Item
                  {...formItemLayout}
                  label="Tên sản phẩm"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên sản phẩm!",
                    },
                  ]}
                >
                  <Input disabled={modalType === "Xoá"} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Tên sản phẩm (Tiếng Anh)"
                  name="engName"
                >
                  <Input disabled={modalType === "Xoá"} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Danh mục"
                  name="subcategory"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn danh mục sản phẩm!",
                    },
                  ]}
                >
                  <TreeSelect
                    disabled={modalType === "Xoá"}
                    treeData={categoryTree}
                    treeDefaultExpandAll
                  />
                </Form.Item>
                <Form.Item {...formItemLayout} label="Giá sản phẩm">
                  <Table
                    columns={[
                      {
                        title: "Kích cỡ",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Mã",
                        dataIndex: "code",
                        key: "code",
                      },
                      {
                        title: "Giá gốc",
                        dataIndex: "price",
                        key: "price",
                      },
                      {
                        title: "Giá KM",
                        dataIndex: "salePrice",
                        key: "salePrice",
                      },
                      {
                        title: "Hành động",
                        key: "action",
                        render: (text, record) =>
                          modalType !== "Xoá" && (
                            <span>
                              <a
                                role="button"
                                onClick={() =>
                                  this.setState({
                                    showSizeModal: true,
                                    sizeFields: record,
                                    sizeModalType: "Chỉnh sửa",
                                  })
                                }
                              >
                                Sửa
                              </a>
                              <Divider type="vertical" />
                              <a
                                role="button"
                                onClick={() =>
                                  this.setState({
                                    showSizeModal: true,
                                    sizeFields: record,
                                    sizeModalType: "Xoá",
                                  })
                                }
                              >
                                Xoá
                              </a>
                            </span>
                          ),
                      },
                    ]}
                    dataSource={sizes}
                    pagination={false}
                    size="small"
                    bordered={false}
                  />
                  {modalType !== "Xoá" && (
                    <Button
                      type="dashed"
                      onClick={() =>
                        this.setState({
                          showSizeModal: true,
                          sizeModalType: "Tạo mới",
                        })
                      }
                    >
                      <Icon.PlusOutlined /> Thêm kích cỡ
                    </Button>
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Màu sắc"
                  name="colors"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập màu sản phẩm!",
                    },
                  ]}
                >
                  <Select mode="tags" disabled={modalType === "Xoá"}>
                    {defaultColors.map((color) => (
                      <Select.Option key={color}>{color}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item {...formItemLayout} label="Hình ảnh">
                  <UploadList>
                    {images.map((item) => (
                      <UploadItem key={item.image}>
                        <img
                          alt=""
                          src={`${API_URL}/upload/THUMB-${item.image}`}
                        />
                        <p>{item.color}</p>
                      </UploadItem>
                    ))}
                    {modalType !== "Xoá" && (
                      <UploadItem
                        style={{
                          border: "1px dashed #d9d9d9",
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                        }}
                        onClick={() => this.setState({ showMedia: true })}
                      >
                        + Chọn ảnh
                      </UploadItem>
                    )}
                  </UploadList>
                </Form.Item>
                {/* <Form.Item
                  {...formItemLayout}
                  label="Sản phẩm mới"
                >
                  {getFieldDecorator('isFeatured', { valuePropName: 'checked' })(
                    <Switch disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Sản phẩm bán chạy"
                >
                  {getFieldDecorator('isBestSeller', { valuePropName: 'checked' })(
                    <Switch disabled={modalType === 'Xoá'} />
                  )}
                </Form.Item> */}
                <Form.Item
                  {...formItemLayout}
                  label="Hiện trên trang chủ"
                  name="homeSection"
                >
                  <TreeSelect
                    disabled={modalType === "Xoá"}
                    treeData={homeSectionTree}
                    treeDefaultExpandAll
                    placeholder="Không chọn"
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Hết hàng"
                  name="isOutOfStock"
                  valuePropName="checked"
                >
                  <Switch disabled={modalType === "Xoá"} />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Mô tả chi tiết" key="2">
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Tiếng Việt (*)" key="1">
                    <Editor
                      value={editorContent}
                      readOnly={modalType === "Xoá"}
                      onChange={(value) =>
                        this.setState({ editorContent: value })
                      }
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Tiếng Anh" key="2">
                    <Editor
                      value={editorContentEng}
                      onChange={(content) =>
                        this.setState({ editorContentEng: content })
                      }
                      readOnly={modalType === "Xoá"}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Modal>
        <Paper
          title="Danh sách sản phẩm"
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                value={search}
                onChange={(e) => this.setState({ search: e.target.value })}
                style={{ width: 200, marginRight: 20 }}
                onSearch={this.handleSearch}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            loading={loading}
            rowKey={(record) => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[
              {
                title: "Ảnh",
                dataIndex: "images",
                key: "images",
                width: 100,
                render: (data) => (
                  <Image
                    shape="square"
                    size={64}
                    icon="picture"
                    src={
                      data.length > 0 &&
                      `${API_URL}/upload/THUMB-${data[0].image}`
                    }
                  />
                ),
              },
              {
                title: "Tên sản phẩm",
                key: "name",
                sorter: true,
                render: (record) => (
                  <a target="__blank" href={`/detail-product/${record._id}`}>
                    {record.name}
                  </a>
                ),
              },
              {
                title: "Danh mục",
                dataIndex: "category",
                key: "category",
                filters: categories.map((item) => ({
                  text: item.name,
                  value: item._id,
                })),
                render: (data) => (
                  <a target="__blank" href={`/category/${data._id}`}>
                    {data.name}
                  </a>
                ),
              },
              {
                title: "Ngày tạo",
                dataIndex: "createdAt",
                sorter: true,
                key: "createdAt",
                render: (data) => <span>{moment(data).format("LT, L")}</span>,
              },
              {
                title: "Hành động",
                key: "action",
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleUp(record)}>
                      Ưu tiên
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={products}
            onChange={this.handleTableChange}
            pagination={{
              total: total,
              defaultCurrent: 1,
              current,
              pageSize: 10,
              showTotal: (sum) => <span>Tổng số: {sum}</span>,
            }}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    products: state.product.list,
    loading: state.product.loading,
    total: state.product.total,
    categories: state.product.categoryList,
    subcategories: state.product.subcategoryList,
    homeSections: state.product.homeSections,
  }),
  {
    getProductList,
    getProductCategoryList,
    getProductSubcategoryList,
    createProduct,
    updateProduct,
    deleteProduct,
    searchAdminProduct,
    prioritizeProduct,
    getHomeSections,
  }
)(Product);
