import React, { Component } from 'react';
import { Modal, Button, Input, Card, Pagination, Spin } from 'antd';
import * as Icon from '@ant-design/icons';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import moment from 'moment';

import { getFileList, uploadFile, deleteFile } from '../actions';
import { API_URL } from '../utils/service';

const TopWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

class Media extends Component {
  state = { selected: null, search: '', uploading: false, current: 1 };

  componentDidMount() {
    this.props.getFileList({ skip: 0, search: this.state.search });
  }

  handleUpload = (e) => {
    this.props.uploadFile(e.target.files[0]);
  }

  handleSelectPage = page => {
    this.setState({ current: page });
    this.props.getFileList({ skip: page - 1, search: this.state.search });
  }

  handleSearch = () => {
    const { search } = this.state;
    this.props.getFileList({ skip: 0, search });
  }

  render() {
    const { selected, search, current } = this.state;
    const { list, loading, onCancel, onSubmit, visible, title, deleteFile, total, ...props } = this.props; // eslint-disable-line
    return (
      <Modal
        title={title || 'Thư viện tải lên'}
        open={visible}
        forceRender
        onCancel={onCancel}
        width='80%'
        footer={onSubmit ? [
          <Button key="back" onClick={onCancel}>Huỷ</Button>,
          <Button key="submit" disabled={selected === null} type="primary" onClick={() => onSubmit(selected)}>Chọn</Button>,
        ] : [<Button key="back" onClick={onCancel}>Đóng lại</Button>]}
        {...props}
      >
        <TopWrapper>
          <Input.Search
            autoFocus
            value={search}
            onChange={e => this.setState({ search: e.target.value })}
            placeholder='Tìm kiếm'
            style={{ width: 200 }}
            onSearch={this.handleSearch}
          />
          <span style={{ float: 'right' }}>
            <input
              type='file'
              ref={fileInput => this.fileInput = fileInput} // eslint-disable-line
              onChange={this.handleUpload}
              style={{ display: 'none' }}
              accept='.png,.jpg'
            />
            <Button type='primary' onClick={() => this.fileInput.click()}><Icon.UploadOutlined />Tải lên</Button>
          </span>
          {selected && <Button style={{ float: 'right', marginRight: 10 }} onClick={() => deleteFile(selected._id)} type='danger'>Xoá</Button>}
        </TopWrapper>
        {loading && <Spin tip='Loading...' />}
        <Row style={{ position: 'relative' }}>
          <div
            role='button'
            style={{ position: 'absolute', width: '100%', height: '100%' }}
            onClick={() => this.setState({ selected: null })}
          />
          {list.map(item => (
            <Col lg={3} key={item._id} style={{ marginBottom: 15 }}>
              <Card
                hoverable
                bordered={false}
                style={{ border: selected && selected._id === item._id ? '2px solid #1890FE' : '2px solid #e8e8e8' }}
                onClick={() => this.setState({ selected: item })}
                cover={<img style={{ height: 170, objectFit: 'cover' }} alt={item.originalname} src={`${API_URL}/upload/THUMB-${item.filename}`} />}
              >
                <Card.Meta
                  title={item.originalname}
                  description={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{`${Math.round(item.size / 1024)} KB`}</span>
                    <span>{`${moment(item.createdAt).fromNow()}`}</span>
                  </div>}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          total={total}
          defaultCurrent={1}
          current={current}
          pageSize={8}
          onChange={this.handleSelectPage}
          showTotal={sum => <span>Tổng số: {sum}</span>}
        />
      </Modal>
    );
  }
}

export default connect(state => ({
  list: state.upload.list,
  total: state.upload.total,
  loading: state.upload.loading,
}), {
  uploadFile,
  getFileList,
  deleteFile,
})(Media);
