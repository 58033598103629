import {
  GET_CONTACT_FORM_LIST_REQUEST,
  GET_CONTACT_FORM_LIST_SUCCESS,
  GET_CONTACT_FORM_LIST_FAILURE,
  CREATE_CONTACT_FORM_SUCCESS,
  DELETE_CONTACT_FORM_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  requesting: false,
  list: [],
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT_FORM_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_CONTACT_FORM_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        list: action.payload.contacts,
        total: action.payload.total,
      };
    case GET_CONTACT_FORM_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case CREATE_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case DELETE_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => (item._id !== action.payload._id)),
      };
    default:
      return state;
  }
};
