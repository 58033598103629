import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from '@reach/router';
import {
  QueryClientProvider,
} from 'react-query'

import store from './store';

import PrivateRoute from './components/private-route';
import NotFoundPage from './pages/404';

import HomePage from './pages/index';
import AdvisoryPage from './pages/advisory';
import StoryPage from './pages/story';
import ProductPage from './pages/product';
import DistributionPage from './pages/store-map';
import NewsPage from './pages/news';
import BlogPage from './pages/blog';
import ShareholderMeetingList from './pages/shareholder-meeting-list';
import ShareholderPage from './pages/shareholder';
import ContactPage from './pages/contact';
import BestSellersPage from './pages/best-sellers';
import CheckoutPage from './pages/checkout';
import NewProductPage from './pages/new-product';
import SearchPage from './pages/search';
import CategoryPage from './pages/category';
import SectionPage from './pages/section';
import SubcategoryPage from './pages/subcategory';
import DetailArticlePage from './pages/detail-article';
import DetailProductPage from './pages/detail-product';

// Admin
import AdminLoginPage from './pages/admin/login';
import AdminUserPage from './pages/admin/user';
import AdminProductPage from './pages/admin/product';
import AdminProductCategoryPage from './pages/admin/product-category';
import AdminArticlePage from './pages/admin/article';
import AdminArticleCategoryPage from './pages/admin/article-category';
import AdminShareholderMeetingPage from './pages/admin/shareholder-meeting';
import AdminConfigHomePage from './pages/admin/config-home';
import AdminConfigContactPage from './pages/admin/config-contact';
import AdminConfigDistributionPage from './pages/admin/config-distribution';
import AdminConfigStoryPage from './pages/admin/config-story';
import AdminConfigDisplayPage from './pages/admin/config-display';
import AdminMediaPage from './pages/admin/media';
import AdminContactFormPage from './pages/admin/contact-form';
import AdminOrderAllPage from './pages/admin/order-all';
import AdminSectionBanner from './pages/admin/section-banner';

// User
import UserDashboardPage from './pages/user/dashboard';
import UserLoginPage from './pages/user/login';
import UserRegisterPage from './pages/user/register';
import { ConfigProvider } from 'antd';
import { queryClient } from './queries/client';
import ShareholderMeeting from './pages/shareholder-meeting';
import PaymentInformation from './pages/payment-information';

class App extends Component {
  render() {
    return (
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#3980F6',
          colorLink: '#3980F6',
          fontFamily: 'Arial, Helvetica, sans-serif'
        },
      }}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Router primary={false}>
              <NotFoundPage default />
              <HomePage path='/' />
              <StoryPage path='story' />
              <ProductPage path='product' />
              <DistributionPage path='store-map' />
              <NewsPage path='news' />
              <PaymentInformation path='payment-information' />
              <ShareholderMeetingList path='shareholder-meeting' />
              <ShareholderMeeting path='shareholder-meeting/:srId' />
              <BlogPage path='blog/:categoryId' />
              <ShareholderPage path='shareholder' />
              <ContactPage path='contact' />
              <AdvisoryPage path='advisory' />
              <BestSellersPage path='best-sellers' />
              <CheckoutPage path='checkout' />
              <NewProductPage path='new-product' />
              <SearchPage path='search' />
              <CategoryPage path='category/:id' />
              <SectionPage path='section/:id' />
              <SubcategoryPage path='subcategory/:id' />
              <DetailArticlePage path='detail-article/:id' />
              <DetailProductPage path='detail-product/:id' />
              <div path='admin'>
                <AdminLoginPage path="/" />
                <PrivateRoute basePath='admin' path="user" component={AdminUserPage} />
                <PrivateRoute basePath='admin' path="product" component={AdminProductPage} />
                <PrivateRoute basePath='admin' path="product-category" component={AdminProductCategoryPage} />
                <PrivateRoute basePath='admin' path="article" component={AdminArticlePage} />
                <PrivateRoute basePath='admin' path="article-category" component={AdminArticleCategoryPage} />
                <PrivateRoute basePath='admin' path="shareholder-meeting" component={AdminShareholderMeetingPage} />
                <PrivateRoute basePath='admin' path="config-display" component={AdminConfigDisplayPage} />
                <PrivateRoute basePath='admin' path="config-home" component={AdminConfigHomePage} />
                <PrivateRoute basePath='admin' path="config-contact" component={AdminConfigContactPage} />
                <PrivateRoute basePath='admin' path="config-distribution" component={AdminConfigDistributionPage} />
                <PrivateRoute basePath='admin' path="config-story" component={AdminConfigStoryPage} />
                <PrivateRoute basePath='admin' path="media" component={AdminMediaPage} />
                <PrivateRoute basePath='admin' path="contact-form" component={AdminContactFormPage} />
                <PrivateRoute basePath='admin' path="order-all" component={AdminOrderAllPage} />
                <PrivateRoute basePath='admin' path="section-banner" component={AdminSectionBanner} />
              </div>
              <div path='user'>
                <UserLoginPage path='/' />
                <UserRegisterPage path='register' />
                <PrivateRoute basePath='user' path="dashboard" component={UserDashboardPage} />
              </div>
            </Router>
          </QueryClientProvider>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;
