import {
  // GET_PRODUCT_CATEGORY_LIST_FAILURE,
  // GET_PRODUCT_CATEGORY_LIST_REQUEST,
  GET_PRODUCT_CATEGORY_LIST_SUCCESS,
  GET_HOME_SECTIONS_SUCCESS,
  // GET_PRODUCT_SUBCATEGORY_LIST_FAILURE,
  // GET_PRODUCT_SUBCATEGORY_LIST_REQUEST,
  GET_PRODUCT_SUBCATEGORY_LIST_SUCCESS,
  // CREATE_PRODUCT_CATEGORY_FAILURE,
  // CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  // UPDATE_PRODUCT_CATEGORY_FAILURE,
  // UPDATE_PRODUCT_CATEGORY_REQUEST,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  // DELETE_PRODUCT_CATEGORY_FAILURE,
  // DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  // CREATE_PRODUCT_SUBCATEGORY_FAILURE,
  // CREATE_PRODUCT_SUBCATEGORY_REQUEST,
  CREATE_PRODUCT_SUBCATEGORY_SUCCESS,
  // UPDATE_PRODUCT_SUBCATEGORY_FAILURE,
  // UPDATE_PRODUCT_SUBCATEGORY_REQUEST,
  UPDATE_PRODUCT_SUBCATEGORY_SUCCESS,
  // DELETE_PRODUCT_SUBCATEGORY_FAILURE,
  // DELETE_PRODUCT_SUBCATEGORY_REQUEST,
  DELETE_PRODUCT_SUBCATEGORY_SUCCESS,
  GET_PRODUCT_LIST_FAILURE,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  // CREATE_PRODUCT_FAILURE,
  // CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  // UPDATE_PRODUCT_FAILURE,
  // UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  // DELETE_PRODUCT_FAILURE,
  // DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAILURE,
  SEARCH_PRODUCT_REQUEST,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_ADMIN_PRODUCT_FAILURE,
  SEARCH_ADMIN_PRODUCT_REQUEST,
  SEARCH_ADMIN_PRODUCT_SUCCESS,
  CHANGE_SEARCH_TEXT,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  total: 0,
  searchResult: [],
  categoryList: [],
  subcategoryList: [],
  searchText: '',
  searching: false,
  loading: false,
  homeSections: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case GET_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.products,
        loading: false,
        total: action.payload.total,
      };
    case GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        searchResult: [],
        searching: true,
      };
    case SEARCH_PRODUCT_FAILURE:
      return {
        ...state,
        searching: false,
      };
    case SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        searchResult: action.payload.products,
        searching: false,
      };
    case SEARCH_ADMIN_PRODUCT_REQUEST:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case SEARCH_ADMIN_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SEARCH_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        list: action.payload.products,
        total: action.payload.total,
        loading: false,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ],
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.payload._id),
      };
    case GET_PRODUCT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
      };
    case GET_HOME_SECTIONS_SUCCESS:
      return {
        ...state,
        homeSections: action.payload,
      };
    case CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: [
          action.payload,
          ...state.categoryList,
        ],
      };
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: state.categoryList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: state.categoryList.filter(item => item._id !== action.payload._id),
      };
    case GET_PRODUCT_SUBCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        subcategoryList: action.payload,
      };
    case CREATE_PRODUCT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategoryList: [
          action.payload,
          ...state.subcategoryList,
        ],
      };
    case UPDATE_PRODUCT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategoryList: state.subcategoryList.map(item => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_PRODUCT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategoryList: state.subcategoryList.filter(item => item._id !== action.payload._id),
      };
    default:
      return state;
  }
}
;
