import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
// import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Pagination } from 'antd';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import ArticlesSkeleton from '../components/articles-skeleton';
import { getAdvisoryPage } from '../actions';
import ArticleCard, {
  ArticleName,
  ArticleMeta,
  ArticleDescription,
  ArticleImage,
} from '../components/article-card';
import { API_URL } from '../utils/service';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #0b3a70;
  margin-left: 15px;
  margin-bottom: 20px;
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'advisory.title' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class NewsPage extends Component {
  state = { current: 1 }

  componentDidMount() {
    this.props.getAdvisoryPage(0);
  }

  handleSelectPage = page => {
    this.setState({ current: page });
    this.props.getAdvisoryPage(page - 1);
  }

  render() {
    const { data, loading, error, locale, total } = this.props;
    const { current } = this.state;

    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper>
            <Title><FormattedMessage id='advisory.title' /></Title>
            {error && <p><FormattedMessage id='error' /></p>}
            <Grid fluid>
              {!loading && data && data.length > 0 && <Row style={{ marginBottom: 20 }}>
                <Col xs={12} md={5} lg={7}>
                  <ArticleImage style={{ height: 275 }} image={`${API_URL}/upload/${data[0].featuredImage}`} />
                </Col>
                <Col xs={12} md={7} lg={5} style={{ position: 'relative' }}>
                  <ArticleMeta style={{ fontSize: 14, marginTop: 10 }}>{data[0].category.name}</ArticleMeta>
                  <ArticleName to={`/detail-article/${data[0]._id}`} style={{ fontSize: 20, lineHeight: '24px', maxHeight: 'unset', '-webkit-line-clamp': 'unset' }}>
                    {locale === 'en' && data[0].engTitle ? data[0].engTitle : data[0].title}
                  </ArticleName>
                  <ArticleDescription>{locale === 'en' && data[0].engExcerpt ? data[0].engExcerpt : data[0].excerpt}</ArticleDescription>
                  {/* <ArticleMeta style={{ fontSize: 14, marginTop: 15 }}>
                    <FormattedMessage id='advisory.createdAt' /> {moment(data[0].createdAt).fromNow()}
                  </ArticleMeta> */}
                </Col>
              </Row>}
              {!loading && data && data.length > 0 ? <Row>
                {data.map((item, idx) => idx !== 0 && (
                  <Col key={item.title} xs={12} md={6} xl={4}>
                    <ArticleCard locale={locale} data={item} />
                  </Col>
                ))}
              </Row> : <ArticlesSkeleton />}
              <Pagination
                total={total || 0}
                defaultCurrent={1}
                current={current}
                pageSize={8}
                onChange={this.handleSelectPage}
              />
            </Grid>
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.advisory,
  total: state.page.advisoryTotal,
  error: state.page.error,
  loading: state.page.loading,
  locale: state.setting.locale,
}), {
  getAdvisoryPage,
})(NewsPage);
