import { query, ENDPOINTS } from '../utils/service';

export const getShareholderMeetingList = () => query({
  endpoint: ENDPOINTS.shareholderMeeting,
})

export const getShareholderMeeting = (id) => query({
  endpoint: ENDPOINTS.shareholderMeeting + '/' + id,
})

export const createShareholderMeeting = (data) => query({
  endpoint: ENDPOINTS.shareholderMeeting,
  method: 'POST',
  data,
})

export const updateShareholderMeeting = (id, data) => query({
  endpoint: `${ENDPOINTS.shareholderMeeting}/${id}`,
  method: 'PATCH',
  data,
})

export const deleteShareholderMeeting = (id) => query({
  endpoint: `${ENDPOINTS.shareholderMeeting}/${id}`,
  method: 'DELETE',
})
