import { query, ENDPOINTS } from '../utils/service';

export const getArticleList = (params) => query({
  endpoint: ENDPOINTS.getArticleList,
  params,
})

export const createArticle = (data) => query({
  endpoint: ENDPOINTS.createArticle,
  method: 'POST',
  data,
})

export const updateArticle = (id, data) => query({
  endpoint: ENDPOINTS.updateArticle + id,
  method: 'PATCH',
  data,
})

export const deleteArticle = (id) => query({
  endpoint: ENDPOINTS.deleteArticle + id,
  method: 'DELETE',
})
