import { Alert } from "antd";
import Layout from "../layouts/layout";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 16px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
  min-height: 70vh;
`;

const PaymentInformation = () => (
  <Layout>
    <Container>
      <Wrapper>
        <h2>Thông tin chuyển khoản</h2>
        <Alert
          type='info'
          style={{ marginTop: 12, padding: '12px 16px' }}
          message={<strong>NGÂN HÀNG QUÂN ĐỘI</strong>}
          description={(
            <div>
              <p>Chủ tài khoản: <strong>CÔNG TY CỔ PHẦN  MAY QUỐC TẾ THẮNG LỢI</strong></p>
              <p>Số tài khoản: <strong>1511101858004</strong></p>
              <p>Chi nhánh: <strong>CN AN PHÚ - TPHCM</strong></p>
              <code>
                <p style={{padding: 12, borderRadius: 8, marginTop: 8, backgroundColor: 'hsla(0, 0%, 0%, 0.04)' }}>
                  Sau khi thanh toán thành công, Quý khách chụp màn hình chuyển khoản thành công và gửi qua Zalo Hotline: <strong>0988045086</strong>. Cảm ơn quý khách!
                </p>
              </code>
            </div>
          )}
        />
      </Wrapper>
    </Container>
  </Layout>
)

export default PaymentInformation
