import { Select } from 'antd'
import React from 'react'
import { useQuery } from 'react-query'
import { getShareholderMeetingList } from '../queries/shareholderMeeting'

const SRSelect = ({...props}) => {
  const { data, isFetching } = useQuery('shareholderMeeting', getShareholderMeetingList, {
    select: data => data ? data.data : []
  })

  return (
    <Select
      loading={isFetching}
      options={(data || []).map(item => ({ label: item.name, value: item._id }))}
      {...props}
    />
  )
}

export default SRSelect
