import React, { Component, Fragment } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { IoMdAdd, IoMdRemove } from 'react-icons/io';
import { connect } from 'react-redux';
import { message } from 'antd';
import { navigate } from '@reach/router';
import { FormattedMessage, injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import ZoomImage from '../components/zoom-image';
import ProductCard from '../components/product-card';
import DetailProductSkeleton from '../components/detail-product-skeleton';
import ProductCol from '../components/product-col';
import { getProductDetail, addToCart } from '../actions';
import { API_URL } from '../utils/service';
import defaultImage from '../images/default-image.png';
import { media } from '../utils/media';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
`;

const GalleryWrapper = styled.div`
  a {
    ::after {
      display: none;
    }
  }
`;

const InfoWrapper = styled.div`
  min-height: 400px;
  padding-left: 20px;
  ${media.tablet`
    padding-left: 0px;
  `};
  h1 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  span {
    color: #90A3C2;
    font-size: 14px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const ActionButton = styled.div`
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   margin-left: 10px;
//   font-size: 20px;
//   color: #90A3C2;
//   :hover {
//     color: #134E8F;
//   }
//   transition: all 0.3s;
// `;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #90A3C2;
  margin: 10px 0px;
  opacity: 0.3;
`;

const Price = styled.div`
  h2 {
    font-size: 28px;
    font-weight: 600;
    color: #134E8F;
    display: inline-block;
  }
  span {
    font-size: 20px;
    color: #90A3C2;
    margin-left: 20px;
    text-decoration: line-through;
  }
`;

const BigButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-left: ${props => (props.primary ? '10px' : 0)};
  border-radius: 2px;
  span {
    color: white;
  }
  background: ${props => (props.primary ? '#134E8F' : '#229BD4')};
  transition: all 0.3s;
  :hover {
    background: ${props => (props.primary ? '#0b3a70' : '#0f7caf')};
  }
`;

const OptionWrapper = styled.div`
  margin: 20px 0px;
  display: flex;
  font-size: 14px;
`;

const OptionName = styled.div`
  width: 100px;
  color: #90A3C2;
`;

const SelectWrapper = styled.div`
  width: calc(100% - 100px);
  p {
    margin-bottom: 6px;
  }
`;

const Option = styled.div`
  font-size: 12px;
  display: inline-block;
  border: 2px solid ${props => (props.selected ? '#0b3a70' : '#90A3C2')};
  border-radius: 2px;
  margin-right: 6px;
  padding: 4px 6px;
  margin-bottom: 6px;
  cursor: pointer;
`;

const QuantityButton = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bccbe5;
  color: #90A3C2;
  border-radius: 2px;
  width: 35px;
  height: 35px;
  margin-right: 6px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    background: #90A3C2;
    color: white;
  }
`;

const QuantityInput = styled.input`
  border: 0px;
  margin-right: 6px;
  width: 25px;
  height: 35px;
  font-size: 14px;
  text-align: center;
`;

const Content = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const ContentTitle = styled.h3`
  margin-bottom: 15px;
  span {
    font-size: 18px;
    color: #134E8F;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    color: #90A3C2;
    margin: 0px;
    display: inline;
    font-weight: 400;
    margin-left: 10px;
  }
`;

const ContentHtml = styled.div`
  ul, h5 {
    margin-bottom: 10px;
    margin-left: 20px;
    list-style: square;
  }
  table {
    table-layout: fixed;
  }
`;

const OutOfStock = styled.div`
  padding: 6px 10px;
  border-radius: 4px;
  background: #444;
  color: white;
`

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'product.title' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class DetailProduct extends Component {
  state = {
    selectedSize: 0,
    selectedColor: 0,
    quantity: 1,
  }

  componentDidMount() {
    this.props.getProductDetail(this.props.id);
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.location !== prevProps.location) window.scrollTo(0,0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.props.getProductDetail(nextProps.id);
    }
  }

  handleBuyNow = () => {
    const { addToCart, data } = this.props; // eslint-disable-line
    const { quantity, selectedColor, selectedSize } = this.state;
    if (quantity > 0) {
      addToCart({
        product: data,
        quantity,
        color: data.colors[selectedColor],
        size: data.sizes[selectedSize].name,
        price: data.sizes[selectedSize].salePrice || data.sizes[selectedSize].price,
      });
      navigate('/checkout');
    } else {
      message.error('Số lượng không hợp lệ');
    }
  }

  handleAddToCart = () => {
    const { addToCart, data } = this.props; // eslint-disable-line
    const { quantity, selectedColor, selectedSize } = this.state;
    if (quantity > 0) {
      addToCart({
        product: data,
        quantity,
        color: data.colors[selectedColor],
        size: data.sizes[selectedSize].name,
        price: data.sizes[selectedSize].salePrice || data.sizes[selectedSize].price,
      });
      message.success('Thêm vào giỏ hàng thành công');
    } else {
      message.error('Số lượng không hợp lệ');
    }
  }

  handleSelectColor = idx => {
    const { data: { colors, images } } = this.props;
    const color = colors[idx];
    let imageIdx;
    images.map((image, index) => {
      if (image.color === color) {
        imageIdx = index;
      }
      return null;
    });
    console.log('xxxx', imageIdx)
    if (imageIdx !== null) {
      this.gallery.slideToIndex(imageIdx);
    }
    this.setState({ selectedColor: idx });
  }

  handleRenderImageGallery = item => (
    <div className='image-gallery-image'>
      <ZoomImage
        data={item}
        onError={e => e.target.src = defaultImage} // eslint-disable-line
      />
    </div>
  )

  render() {
    const { selectedColor, selectedSize, quantity } = this.state;
    const { data, loading, error, locale } = this.props;

    if (error) return <p><FormattedMessage id='error' /></p>;

    const salePrice = data ? data.sizes[selectedSize].salePrice : 0;
    const price = data ? data.sizes[selectedSize].price : 0;

    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          {!loading && data ? (
            <Fragment>
              <Wrapper style={{ padding: '20px 20px 10px' }}>
                <Row>
                  <Col xs={12} md={6}>
                    <GalleryWrapper>
                      <ImageGallery
                        items={data.images.map(item =>
                          ({ original: `${API_URL}/upload/${item.image}`, thumbnail: `${API_URL}/upload/THUMB-${item.image}` }))}
                        showPlayButton={false}
                        showNav={false}
                        showFullscreenButton={false}
                        defaultImage={defaultImage}
                        renderItem={this.handleRenderImageGallery}
                        ref={e => this.gallery = e} // eslint-disable-line
                      />
                    </GalleryWrapper>
                  </Col>
                  <Col xs={12} md={6}>
                    <InfoWrapper>
                      {/* <FlexWrapper>
                        <div>
                          <h1>{locale === 'en' && data.engName ? data.engName : data.name}</h1>
                        </div>
                        <FlexWrapper>
                          <ActionButton>
                            <MdShare />
                          </ActionButton>
                          <ActionButton style={{ fontSize: 22 }}>
                            <MdFavoriteBorder />
                          </ActionButton>
                        </FlexWrapper>
                      </FlexWrapper>
                      <Divider /> */}
                      <OptionWrapper>
                        <OptionName>
                          <FormattedMessage id='product.size' />
                        </OptionName>
                        <SelectWrapper>
                          <p>
                            {data.sizes[selectedSize].name}&nbsp;
                            {data.sizes[selectedSize].code && <Fragment>
                          (<span><FormattedMessage id='product.code' />:&nbsp;</span>
                              {data.sizes[selectedSize].code})
                            </Fragment>}
                          </p>
                          <div>
                            {data.sizes.map((item, idx) => (
                              <Option key={idx} selected={selectedSize === idx} onClick={() => this.setState({ selectedSize: idx })}>{item.name}</Option>
                            ))}
                          </div>
                        </SelectWrapper>
                      </OptionWrapper>
                      <OptionWrapper>
                        <OptionName>
                          <FormattedMessage id='product.price' />
                        </OptionName>
                        <Price>
                          <h2>{salePrice ? salePrice.toLocaleString() : price.toLocaleString()} ₫</h2>
                          {salePrice > 0 && <span>{price.toLocaleString()} ₫</span>}
                        </Price>
                      </OptionWrapper>
                      <OptionWrapper>
                        <OptionName>
                          <FormattedMessage id='product.color' />
                        </OptionName>
                        <SelectWrapper>
                          <p>{data.colors[selectedColor]}</p>
                          <div>
                            {data.colors.map((item, idx) => (
                              <Option key={idx} selected={selectedColor === idx} onClick={() => this.handleSelectColor(idx)}>{item}</Option>
                            ))}
                          </div>
                        </SelectWrapper>
                      </OptionWrapper>
                      <OptionWrapper>
                        <OptionName>
                          <FormattedMessage id='product.quantity' />
                        </OptionName>
                        {data.isOutOfStock ? <OutOfStock>Đã hết hàng</OutOfStock> : (
                          <SelectWrapper style={{ display: 'flex' }}>
                            <QuantityButton onClick={() => this.setState({ quantity: quantity - 1 })}>
                              <IoMdRemove />
                            </QuantityButton>
                            <QuantityInput value={quantity} onChange={e => this.setState({ quantity: Number(e.target.value) })} />
                            <QuantityButton onClick={() => this.setState({ quantity: quantity + 1 })}>
                              <IoMdAdd />
                            </QuantityButton>
                          </SelectWrapper>
                        )}
                      </OptionWrapper>
                      {!data.isOutOfStock && <FlexWrapper>
                        <BigButton onClick={this.handleBuyNow}><FormattedMessage id='product.buyNow' /></BigButton>
                        <BigButton onClick={this.handleAddToCart} primary><FormattedMessage id='product.addToCart' /></BigButton>
                      </FlexWrapper>}
                      <Divider />
                      <Content>
                        <ContentTitle>
                          <FormattedMessage id='product.detail' /> <p>{locale === 'en' && data.engName ? data.engName : data.name}</p>
                        </ContentTitle>
                        <ContentHtml dangerouslySetInnerHTML={{ __html: locale === 'en' && data.engContent ? data.engContent : data.content }} />
                      </Content>
                    </InfoWrapper>
                  </Col>
                </Row>
              </Wrapper>
              {/* <Wrapper style={{ padding: 20 }}>
                <Content>
                  <ContentTitle>
                    <FormattedMessage id='product.detail' /> <p>{locale === 'en' && data.engName ? data.engName : data.name}</p>
                  </ContentTitle>
                  <ContentHtml dangerouslySetInnerHTML={{ __html: locale === 'en' && data.engContent ? data.engContent : data.content }} />
                </Content>
              </Wrapper> */}
              <Wrapper style={{ padding: '20px 20px 0px' }}>
                <ContentTitle><FormattedMessage id='product.related' /></ContentTitle>
                <Row>
                  {(data.relatedProducts && data.relatedProducts.length > 0)
                    ? data.relatedProducts.map(product => (
                      <ProductCol key={product._id}>
                        <ProductCard locale={locale} data={product} />
                      </ProductCol>
                    ))
                    : <span style={{ marginLeft: 20, marginBottom: 20 }}><FormattedMessage id='product.noProduct' /></span>}
                </Row>
              </Wrapper>
            </Fragment>
          ) : <DetailProductSkeleton />}
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  data: state.page.detailProduct,
  error: state.page.error,
  loading: state.page.loading,
  locale: state.setting.locale,
}), {
  getProductDetail,
  addToCart,
})(DetailProduct);
