import React, { useEffect, useState } from 'react';
import {
  Input, Button, Table, Divider, Form, Modal, Select, Tabs, Image,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getArticleCategoryList,
} from '../../actions';
import Layout, { Paper } from '../../layouts/admin';
import Media from '../../components/media';
import Editor from '../../components/editor';
import { query, ENDPOINTS, API_URL } from '../../utils/service';
import SRSelect from '../../components/sr-select';
import { useMutation, useQuery } from 'react-query';
import { createArticle, deleteArticle, getArticleList, updateArticle } from '../../queries/article';
import { queryClient } from '../../queries/client';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

const Article = ({ getArticleCategoryList, categories }) => {
  const [form] = Form.useForm();
  const category = Form.useWatch('category', form);
  const [{skip, limit}, setPagination] = useState({skip: 0, limit: 10});
  const {data, isFetching} = useQuery(['articleList', skip, limit], () => getArticleList({skip, limit}), {
    select: data => data ? data.data : {},
  })
  const { mutateAsync: createArticleAsync, isLoading: isCreating } = useMutation((values) => createArticle(values))
  const { mutateAsync: updateArticleAsync, isLoading: isUpdating } = useMutation(({id, values}) => updateArticle(id, values))
  const { mutateAsync: deleteArticleAsync, isLoading: isDeleting } = useMutation((id) => deleteArticle(id))

  const articles = data ? data.articles : [];
  const total = data ? data.total : 0;

  const [{
    showMedia,
    showModal,
    modalType,
    selectedRowKeys,
    editorContent,
    editorContentEng,
  }, setState] = useState({
    showMedia: false,
    showModal: false,
    modalType: "Tạo mới",
    selectedRowKeys: [],
    editorContent: '',
    editorContentEng: '',
  })

  useEffect(() => {
    getArticleCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectChange = (selectedRowKeys) => {
    setState(state => ({...state, selectedRowKeys }));
  }

  const setShowModal = modalType => setState(state => ({...state,
    showModal: true,
    modalType,
  }))

  const hideModal = () => {
    setState(state => ({...state, showModal: false, editorContent: '', editorContentEng: '' }));
    form?.resetFields();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    form?.validateFields().then(async values  => {
      values.content = editorContent // eslint-disable-line
      values.engContent = editorContentEng // eslint-disable-line
      if (modalType === 'Tạo mới') {
        await createArticleAsync(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        await updateArticleAsync({id, values});
      } else if (modalType === "Xoá") {
        await deleteArticleAsync(values._id);
      }
      queryClient.invalidateQueries('articleList');
      setState(state => ({...state, showModal: false, editorContent: '' }));
      form?.resetFields();
    });
  };

  const handleEdit = async record => {
    setShowModal('Chỉnh sửa');
    form?.setFieldsValue({
      ...record,
      category: record.category._id,
      shareholderMeeting: record.shareholderMeeting && record.shareholderMeeting._id,
    });
    getDetailContent(record._id);
  }

  const handleDelete = async record => {
    setShowModal('Xoá');
    form?.setFieldsValue({
      ...record,
      category: record.category._id,
      shareholderMeeting: record.shareholderMeeting && record.shareholderMeeting._id,
    });
    getDetailContent(record._id);
  }

  const getDetailContent = async id => {
    setState(state => ({...state, editorContent: 'Loading...', editorContentEng: 'Loading...' }));
    const { data } = await query({
      endpoint: ENDPOINTS.getArticleDetail + id,
    });
    setState(state => ({...state, editorContent: data.article.content, editorContentEng: data.article.engContent }));
  }

  return (
    <Layout location='/admin/article'>
      <Media
        visible={showMedia}
        onCancel={() => setState(state => ({...state, showMedia: false }))}
        onSubmit={file => {
          form?.setFieldsValue({ featuredImage: file.filename });
          setState(state => ({...state, showMedia: false }));
        }}
      />
      <Modal
        width='70%'
        title={`${modalType} bài viết`}
        open={showModal}
        onOk={handleSubmit}
        onCancel={hideModal}
        forceRender
        zIndex={500}
        footer={[
          <Button key="back" onClick={hideModal}>Huỷ</Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}
            loading={isCreating || isUpdating || isDeleting}
          >Xác nhận</Button>,
        ]}
      >
        <Form onSubmit={handleSubmit} form={form}>
          <Form.Item noStyle name='_id'>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Tiêu đề"
            name='title'
            rules={[{
              required: true, message: 'Vui lòng nhập tên bài viết!',
            }]}
          >
            <Input disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Tiêu đề (Tiếng Anh)"
            name='engTitle'
          >
            <Input disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='Hình ảnh'
            name='featuredImage'
            rules={[{ required: true, message: 'Vui lòng chọn hình bài viết!' }]}
          >
            <Input onClick={() => setState(state => ({...state, showMedia: true }))} disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label='Danh mục'
            name='category'
            rules={[{
              required: true, message: 'Vui lòng chọn danh mục!',
            }]}
          >
            <Select>
              {categories.map(item => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {category === '63bb940f81cac63b2547ffaa' && <Form.Item
            {...formItemLayout}
            label="Đại hội Cổ Đông"
            name='shareholderMeeting'
          >
            <SRSelect
              placeholder='Chọn đại hội cổ đông'
            />
          </Form.Item>}
          <Form.Item
            {...formItemLayout}
            label='Nội dung'
          >
            <Tabs defaultActiveKey='1'>
              <Tabs.TabPane tab="Tiếng Việt (*)" key="1">
                <Editor
                  value={editorContent || ''}
                  onChange={(content) => setState(state => ({...state, editorContent: content }))}
                  readOnly={modalType === 'Xoá'}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tiếng Anh" key="2">
                <Editor
                  value={editorContentEng || ''}
                  onChange={(content) => setState(state => ({...state, editorContentEng: content }))}
                  readOnly={modalType === 'Xoá'}
                />
              </Tabs.TabPane>
            </Tabs>
          </Form.Item>
        </Form>
      </Modal>
      <Paper
        title='Danh sách bài viết'
        extra={<div>
          <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
          <Button onClick={() => setShowModal('Tạo mới')} type='primary'>Tạo mới</Button>
        </div>}
      >
        <Table
          bordered
          loading={isFetching}
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={[{
            title: 'Ảnh',
            dataIndex: 'featuredImage',
            key: 'featuredImage',
            width: 100,
            render: data => <Image shape="square" size={64} icon="picture" src={`${API_URL}/upload/THUMB-${data}`} style={{objectFit: 'contain'}} />,
          }, {
            title: 'Tiêu đề',
            key: 'title',
            render: record => <a target='__blank' href={`/detail-article/${record._id}`}>{record.title}</a>
          }, {
            title: 'Danh mục',
            dataIndex: ['category', '_id'],
            key: 'category',
            render: (value, record) => {
              if (value !== '63bb940f81cac63b2547ffaa') {
                return <span>{record.category ? record.category.name : ''}</span>
              }
              return <span>{record.shareholderMeeting ? record.shareholderMeeting.name : record.category ? record.category.name : ''}</span>
            }
          }, {
            title: 'Người đăng',
            dataIndex: ['createdBy', 'fullname'],
            key: 'createdBy',
            render: data => <a>{data}</a>,
          }, {
            title: 'Ngày đăng',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: data => <span>{moment(data).fromNow()}</span>,
          }, {
            title: 'Hành động',
            key: 'action',
            width: 110,
            render: (record) => (
              <span>
                <a role='button' onClick={() => handleEdit(record)}>Sửa</a>
                <Divider type="vertical" />
                <a role='button' onClick={() => handleDelete(record)}>Xoá</a>
              </span>
            ),
          }]}
          dataSource={articles}
          pagination={{
            total,
            pageSize: 10,
            showSizeChanger: false,
            onChange: (page) => {
              setPagination(pagination => ({...pagination, skip: (page - 1) * 10 }));
            },
          }}
        />
      </Paper>
    </Layout>
  );
}

export default connect(state => ({
  categories: state.article.categoryList,
}), {
  getArticleCategoryList,
})(Article);
