import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate, Link } from "@reach/router";
import { Form, Input, Button, Checkbox, Layout, message } from "antd";
import * as Icon from "@ant-design/icons";

import { login } from "../../actions";
import { Logo } from "../../layouts/admin";

class Login extends Component {
  formRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    const { userInfo } = this.props;
    if (
      nextProps.userInfo &&
      (!userInfo || userInfo.role !== nextProps.userInfo.role) &&
      nextProps.userInfo.role !== 2
    ) {
      message.error("Không có quyền truy cập!");
    }
  }

  handleSubmit = (event) => {
    // console.log("handleSubmit");
    // event.preventDefault();
    this.formRef.current
      ?.validateFields()
      .then((values) => this.props.login(values));
  };

  render() {
    const { isLogged, userInfo } = this.props;

    if (isLogged && userInfo && userInfo.role === 2) {
      navigate("/admin/user");
    }

    return (
      <Layout style={{ minHeight: "100vh", paddingTop: "18vh" }}>
        <Layout.Content style={{ maxWidth: 500, margin: "0 auto" }}>
          <Logo style={{ marginBottom: 50 }} />
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Vui lòng điền tên tài khoản!" },
              ]}
            >
              <Input
                prefix={
                  <Icon.UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Tên tài khoản"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Vui lòng điền mật khẩu!" }]}
            >
              <Input.Password
                prefix={
                  <Icon.LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Mật khẩu"
              />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>Ghi nhớ</Checkbox>
              <Link
                to="/admin/forget-password"
                className="login-form-forgot"
                href=""
              >
                Quên mật khẩu
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          Thang Loi Corp © {new Date().getFullYear()} | Developed by{" "}
          <a href="https://innoteq.vn" target="__blank">
            Innoteq Ltd
          </a>
        </Layout.Footer>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    isLogged: state.user.isLogged,
    userInfo: state.user.userInfo,
  }),
  {
    login,
  }
)(Login);
