import axios from 'axios';

import store from '../store';

// export const API_URL = 'http://localhost:8123';
export const API_URL = 'https://thangloi-backend.onrender.com';

export const query = async ({
  method = 'GET',
  endpoint = '/',
  data = {},
  headers = {},
  params = {},
}) => await axios({
  method,
  url: API_URL + endpoint,
  data,
  params,
  headers: store.getState().user.isLogged ? { ...headers, token: store.getState().user.userInfo.token } : headers,
});

export const ENDPOINTS = {
  login: '/user/login',
  getUserList: '/user',
  registerUser: '/user/register',
  createUser: '/user/add',
  updateUser: '/user/',
  deleteUser: '/user/',
  uploadFile: '/upload',
  uploadArticleImage: '/upload/article',
  getFile: '/upload/',
  getFileList: '/upload',
  deleteFile: '/upload/',
  getArticleList: '/article',
  getArticleYearCount: '/article/year-count',
  getCategoryDetail: '/article-category/',
  createArticle: '/article',
  updateArticle: '/article/',
  deleteArticle: '/article/',
  getStoryList: '/th-story',
  createStory: '/th-story',
  updateStory: '/th-story/',
  deleteStory: '/th-story/',
  getArticleCategoryList: '/article-category',
  createArticleCategory: '/article-category',
  updateArticleCategory: '/article-category/',
  deleteArticleCategory: '/article-category/',
  getProductList: '/product',
  createProduct: '/product',
  updateProduct: '/product/',
  deleteProduct: '/product/',
  getBannerList: '/banner',
  getHomeSectionBannerList: '/banner/home-section',
  updateHomeSectionBanner: '/banner/home-section',
  createBanner: '/banner',
  updateBanner: '/banner/',
  deleteBanner: '/banner/',
  getHomeSections: '/product/home-sections',
  getContactList: '/contact-information',
  createContact: '/contact-information',
  updateContact: '/contact-information/',
  deleteContact: '/contact-information/',
  getProductCategoryList: '/product-category',
  createProductCategory: '/product-category',
  updateProductCategory: '/product-category/',
  deleteProductCategory: '/product-category/',
  getProductSubcategoryList: '/product-subcategory',
  createProductSubcategory: '/product-subcategory',
  updateProductSubcategory: '/product-subcategory/',
  deleteProductSubcategory: '/product-subcategory/',
  getDistributionList: '/distribution-system',
  createDistribution: '/distribution-system',
  updateDistribution: '/distribution-system/',
  deleteDistribution: '/distribution-system/',
  getTopMenuList: '/top-menu',
  createTopMenu: '/top-menu',
  updateTopMenu: '/top-menu/',
  deleteTopMenu: '/top-menu/',
  getSocialLinkList: '/social-link',
  createSocialLink: '/social-link',
  updateSocialLink: '/social-link/',
  deleteSocialLink: '/social-link/',
  getFooterMenuList: '/footer-menu',
  createFooterMenu: '/footer-menu',
  updateFooterMenu: '/footer-menu/',
  deleteFooterMenu: '/footer-menu/',
  getProductMenuList: '/product-menu',
  createProductMenu: '/product-menu',
  updateProductMenu: '/product-menu/',
  deleteProductMenu: '/product-menu/',
  getLogoList: '/logo',
  createLogo: '/logo',
  updateLogo: '/logo/',
  deleteLogo: '/logo/',
  getReviewList: '/review',
  createReview: '/review',
  updateReview: '/review/',
  deleteReview: '/review/',
  getContactFormList: '/contact-form',
  createContactForm: '/contact-form',
  deleteContactForm: '/contact-form/',
  getHome: '/page/home',
  getLayout: '/page/layout',
  getProduct: '/page/product',
  getProductPage: '/page/product',
  getProductDetail: '/product/',
  getArticleDetail: '/article/',
  getYoutube: '/clip',
  createYoutube: '/clip',
  createOrder: '/order',
  getOrderList: '/order',
  getMyOrderList: '/order/me',
  getOrderDetail: '/order/detail/',
  deleteOrder: '/order/',
  updateStatus: '/order/status/',
  shareholderMeeting: '/shareholder-meeting',
};
