import {
  TOGGLE_MENU, CHANGE_LOCALE,
} from '../actions';

const INITIAL_STATE = {
  menuCollapsed: false,
  locale: 'vi',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        menuCollapsed: !state.menuCollapsed,
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};
