import React, { Component } from "react";
import {
  Input,
  Button,
  Table,
  Divider,
  Form,
  Modal,
  Select,
  message,
  Tabs,
  Avatar,
  Tag,
} from "antd";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  getHomeSectionBannerList,
  updateHomeSectionBanner,
  getBannerList,
  createBanner,
  updateBanner,
  deleteBanner,
} from "../../actions";
import Layout, { Paper } from "../../layouts/admin";
import Media from "../../components/media";
import { API_URL } from "../../utils/service";

class SectionBanner extends Component {
  formRef = React.createRef();
  formBannerRef = React.createRef();

  state = {
    bannerSelectedRowKeys: [],
    showBannerModal: false,
    bannerModalType: "Tạo mới",
    showBannerMedia: false,
    sectionSelectedRowKeys: [],
    showSectionModal: false,
  };

  componentDidMount() {
    this.props.getHomeSectionBannerList();
    this.props.getBannerList();
  }

  onSectionSelectChange = (sectionSelectedRowKeys) => {
    this.setState({ sectionSelectedRowKeys });
  };

  showSectionModal = () =>
    this.setState({
      showSectionModal: true,
    });

  hideSectionModal = () => {
    this.setState({ showSectionModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmitSection = (e) => {
    // e.preventDefault();
    const { updateHomeSectionBanner } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      updateHomeSectionBanner({
        homeSectionKey: values.key,
        banner: values.banner,
        bannerLeft: values.bannerLeft,
      });
      this.setState({ showSectionModal: false });
      this.formRef.current?.resetFields();
    });
  };

  onBannerSelectChange = (bannerSelectedRowKeys) => {
    this.setState({ bannerSelectedRowKeys });
  };

  showBannerModal = (bannerModalType) =>
    this.setState({
      showBannerModal: true,
      bannerModalType,
    });

  hideBannerModal = () => {
    this.setState({ showBannerModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmitBanner = (e) => {
    // e.preventDefault();
    const { bannerModalType } = this.state;
    const { createBanner, updateBanner, deleteBanner } = this.props; // eslint-disable-line
    this.formBannerRef.current?.validateFields().then((values) => {
      if (bannerModalType === "Tạo mới") {
        createBanner(values);
      } else if (bannerModalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateBanner(id, values);
      } else if (bannerModalType === "Xoá") {
        deleteBanner(values._id);
      }
      this.setState({ showBannerModal: false });
      this.formBannerRef.current?.resetFields();
    });
  };

  handleEditBanner = (record) => {
    this.formBannerRef.current?.setFieldsValue(record);
    this.showBannerModal("Chỉnh sửa");
  };

  handleEditSection = (record) => {
    this.formRef.current?.setFieldsValue({
      ...record,
      banner: record.banner ? record.banner._id : null,
      bannerLeft: record.bannerLeft ? record.bannerLeft._id : null,
    });
    this.showSectionModal();
  };

  handleDeleteBanner = (record) => {
    this.formBannerRef.current?.setFieldsValue(record);
    this.showBannerModal("Xoá");
  };

  render() {
    const { homeSections, banners } = this.props;
    const {
      bannerSelectedRowKeys,
      showBannerModal,
      bannerModalType,
      showBannerMedia,
      sectionSelectedRowKeys,
      showSectionModal,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Layout location="/admin/config-banner">
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 0 }}>
          <Tabs.TabPane tab="Danh mục trang chủ" key="1">
            <Modal
              title={`Cập nhật banner`}
              open={showSectionModal}
              onOk={this.handleSubmitSection}
              onCancel={this.hideSectionModal}
              forceRender
              footer={[
                <Button key="back" onClick={this.hideSectionModal}>
                  Huỷ
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleSubmitSection}
                >
                  Xác nhận
                </Button>,
              ]}
            >
              <Form
                onFinish={this.handleSubmitSection}
                ref={this.formRef}
                name="section"
              >
                <Form.Item noStyle name="key">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Banner ngang"
                  name="banner"
                >
                  <Select>
                    <Select.Option value={null}>Không chọn</Select.Option>
                    {banners
                      .filter((i) => i.type === 1)
                      .map((banner) => (
                        <Select.Option value={banner._id}>
                          {banner.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Banner dọc"
                  name="bannerLeft"
                >
                  <Select>
                    <Select.Option value={null}>Không chọn</Select.Option>
                    {banners
                      .filter((i) => i.type === 2)
                      .map((banner) => (
                        <Select.Option value={banner._id}>
                          {banner.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Modal>
            <Paper title="Danh mục trang chủ">
              <Table
                bordered
                rowKey={(record) => record.key}
                rowSelection={{
                  sectionSelectedRowKeys,
                  onChange: this.onSectionSelectChange,
                }}
                columns={[
                  {
                    title: "Mục",
                    dataIndex: "label",
                    key: "label",
                  },
                  {
                    title: "Banner ngang",
                    dataIndex: "banner",
                    key: "banner",
                    render: (record) => record && record.name,
                  },
                  {
                    title: "Banner dọc",
                    dataIndex: "bannerLeft",
                    key: "bannerLeft",
                    render: (record) => record && record.name,
                  },
                  {
                    title: "Hành động",
                    key: "action",
                    width: 185,
                    render: (record) =>
                      !record.items && (
                        <span>
                          <a
                            role="button"
                            onClick={() => this.handleEditSection(record)}
                          >
                            Sửa
                          </a>
                        </span>
                      ),
                  },
                ]}
                dataSource={homeSections}
                defaultExpandAllRows
                childrenColumnName="items"
              />
            </Paper>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Quản lý banner" key="2">
            <Media
              visible={showBannerMedia}
              onCancel={() => this.setState({ showBannerMedia: false })}
              onSubmit={(file) => {
                this.formRef.current?.setFieldsValue({
                  featuredImage: file.filename,
                });
                this.formBannerRef.current?.setFieldsValue({
                  featuredImage: file.filename,
                });
                this.setState({ showBannerMedia: false });
              }}
            />
            <Modal
              title={`${bannerModalType} banner`}
              open={showBannerModal}
              onOk={this.handleSubmitBanner}
              forceRender
              onCancel={this.hideBannerModal}
              footer={[
                <Button key="back" onClick={this.hideBannerModal}>
                  Huỷ
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleSubmitBanner}
                >
                  Xác nhận
                </Button>,
              ]}
            >
              <Form
                onFinish={this.handleSubmitBanner}
                name="banner"
                ref={this.formBannerRef}
              >
                <Form.Item noStyle name="_id">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item {...formItemLayout} label="Tên banner" name="name">
                  <Input disabled={bannerModalType === "Xoá"} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Hình ảnh"
                  name="featuredImage"
                >
                  <Input
                    onClick={() => this.setState({ showBannerMedia: true })}
                    disabled={bannerModalType === "Xoá"}
                  />
                </Form.Item>
                <Form.Item {...formItemLayout} label="URL" name="link">
                  <Input disabled={bannerModalType === "Xoá"} />
                </Form.Item>
                <Form.Item {...formItemLayout} label="Loại banner" name="type">
                  <Select disabled={bannerModalType === "Xoá"} defaultValue={1}>
                    <Select.Option value={1}>
                      Banner ngang (2400px x 292px)
                    </Select.Option>
                    <Select.Option value={2}>
                      Banner dọc (615px x 1844px)
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Modal>
            <Paper
              title="Banner"
              extra={
                <div>
                  <Input.Search
                    placeholder="Tìm kiếm"
                    style={{ width: 200, marginRight: 20 }}
                  />
                  <Button
                    onClick={() => this.showBannerModal("Tạo mới")}
                    type="primary"
                  >
                    Tạo mới
                  </Button>
                </div>
              }
            >
              <Table
                bordered
                rowKey={(record) => record._id}
                rowSelection={{
                  bannerSelectedRowKeys,
                  onChange: this.onBannerSelectChange,
                }}
                columns={[
                  {
                    title: "Ảnh",
                    dataIndex: "featuredImage",
                    key: "featuredImage",
                    width: 100,
                    render: (data) => (
                      <Avatar
                        shape="square"
                        size={64}
                        icon="picture"
                        src={`${API_URL}/upload/${data}`}
                      />
                    ),
                  },
                  {
                    title: "Tên",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "URL",
                    dataIndex: "link",
                    key: "link",
                  },
                  {
                    title: "Loại",
                    dataIndex: "type",
                    key: "type",
                    render: (data) =>
                      data === 1 ? (
                        <Tag>BANNER NGANG</Tag>
                      ) : (
                        <Tag>BANNER DỌC</Tag>
                      ),
                  },
                  {
                    title: "Hành động",
                    key: "action",
                    width: 185,
                    render: (record) => (
                      <span>
                        <a
                          role="button"
                          onClick={() => this.handleEditBanner(record)}
                        >
                          Sửa
                        </a>
                        <Divider type="vertical" />
                        <a
                          role="button"
                          onClick={() => this.handleDeleteBanner(record)}
                        >
                          Xoá
                        </a>
                        <Divider type="vertical" />
                        <CopyToClipboard
                          text={`/banner/${record._id}`}
                          onCopy={() => message.success("Copy URL thành công!")}
                        >
                          <a>Copy URL</a>
                        </CopyToClipboard>
                      </span>
                    ),
                  },
                ]}
                dataSource={banners}
              />
            </Paper>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    banners: state.banner.list,
    homeSections: state.banner.homeSections,
  }),
  {
    getHomeSectionBannerList,
    updateHomeSectionBanner,
    getBannerList,
    createBanner,
    updateBanner,
    deleteBanner,
  }
)(SectionBanner);
