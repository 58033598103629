import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import { createContactForm } from '../actions';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
  overflow: visible;
`;

const IFrame = styled.iframe`
  border-width: 0px;
`

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'shareholder' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

class ContactPage extends Component {
  render() {
    const { locale } = this.props;
    return (
      <Layout locale={locale}>
        <Container>
          <InjectedPageBreadcumb />
          <Wrapper>
              <IFrame
                title="shareholder-relation"
                src="https://ezir.fpts.com.vn/thongtindoanhnghiepclient/TLI"
                width="100%"
                height="2400px"
                name="the-iframe"
                frameborder="0"
              />
          </Wrapper>
        </Container>
      </Layout>
    );
  }
}

export default connect(state => ({
  locale: state.setting.locale,
}), {
  createContactForm,
})(ContactPage);
