import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Divider, Tag, Input, Button, Modal, Form, Radio } from "antd";

import Layout, { Paper } from "../../layouts/admin";
import { getUserList, createUser, updateUser, deleteUser } from "../../actions";

const getRole = (roleId) => {
  switch (roleId) {
    case 1:
      return "customer";
    case 2:
      return "admin";
    default:
      return "undefined";
  }
};

class User extends Component {
  formRef = React.createRef();

  state = {
    selectedRowKeys: [],
    showModal: false,
    modalType: "Tạo mới",
  };

  componentDidMount() {
    this.props.getUserList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  handleModalSubmit = (e) => {
    // e.preventDefault();
    const { modalType } = this.state;
    const { createUser, updateUser, deleteUser } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      if (modalType === "Tạo mới") {
        createUser(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateUser(id, values);
      } else if (modalType === "Xoá") {
        deleteUser(values._id);
      }
      this.setState({ showModal: false });
      this.formRef.current?.resetFields();
    });
  };

  handleModalCancel = () => {
    this.setState({ showModal: false });
    this.formRef.current?.resetFields();
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Xoá");
  };

  render() {
    const { userList } = this.props;
    const { selectedRowKeys, modalType, showModal } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Layout location="/admin/user">
        <Modal
          title={`${modalType} tài khoản`}
          open={showModal}
          onOk={this.handleModalSubmit}
          forceRender
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}>
              Huỷ
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleModalSubmit}
            >
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleModalSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Họ và tên"
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ và tên!",
                },
              ]}
            >
              <Input type="fullname" disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Vai trò"
              name="role"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn vai trò!",
                },
              ]}
            >
              <Radio.Group>
                <Radio disabled={modalType === "Xoá"} value={1}>
                  Khách hàng
                </Radio>
                <Radio disabled={modalType === "Xoá"} value={2}>
                  Quản trị viên
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Địa chỉ email không hợp lệ!",
                },
                {
                  required: true,
                  message: "Vui lòng nhập Email!",
                },
              ]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên tài khoản"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên tài khoản!",
                  whitespace: false,
                },
              ]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            {modalType !== "Xoá" && (
              <Form.Item
                {...formItemLayout}
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: modalType === "Tạo mới",
                    message: "Vui lòng nhập mật khẩu!",
                  },
                ]}
              >
                <Input.Password
                  disabled={modalType === "Xoá"}
                  type="password"
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
        <Paper
          title="Danh sách người dùng"
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[
              {
                title: "Họ và tên",
                dataIndex: "fullname",
                key: "fullname",
                width: 250,
                render: (text) => <a>{text}</a>,
              },
              {
                title: "Tài khoản",
                dataIndex: "username",
                key: "username",
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
                render: (text) => <a href={`mailto:${text}`}>{text}</a>,
              },
              {
                title: "Vai trò",
                dataIndex: "role",
                key: "role",
                width: 100,
                render: (role) => (
                  <span>
                    <Tag color={role === 2 ? "red" : "blue"}>
                      {getRole(role)}
                    </Tag>
                  </span>
                ),
              },
              {
                title: "Hành động",
                key: "action",
                width: 110,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={userList}
          />
        </Paper>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    userList: state.user.userList,
    totalUser: state.user.totalUser,
  }),
  {
    getUserList,
    createUser,
    updateUser,
    deleteUser,
  }
)(User);
