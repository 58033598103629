import React, { Component, Fragment } from "react";
import { Input, Button, Table, Divider, Form, Modal } from "antd";
import { connect } from "react-redux";

import {
  getTopMenuList,
  createTopMenu,
  updateTopMenu,
  deleteTopMenu,
} from "../../../actions";
import { Paper } from "../../../layouts/admin";

class TopMenu extends Component {
  formRef = React.createRef();

  state = {
    showModal: false,
    modalType: "Tạo mới",
    showMedia: false,
  };

  componentDidMount() {
    this.props.getTopMenuList();
  }

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({ showModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType } = this.state;
    const { createTopMenu, updateTopMenu, deleteTopMenu } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      if (modalType === "Tạo mới") {
        createTopMenu(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateTopMenu(id, values);
      } else if (modalType === "Xoá") {
        deleteTopMenu(values._id);
      }
      this.setState({ showModal: false });
      this.formRef.current?.resetFields();
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Xoá");
  };

  render() {
    const { list } = this.props;
    const { showModal, modalType } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Modal
          title={`${modalType} trình đơn`}
          open={showModal}
          onOk={this.handleSubmit}
          forceRender
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên hiển thị"
              name="title"
              rules={[
                { required: true, message: "Vui lòng nhập tên hiển thị!" },
              ]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Tên hiển thị (Tiếng Anh)"
              name="engTitle"
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết (URL)"
              name="url"
              rules={[{ required: true, message: "Vui lòng nhập liên kết!" }]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: "calc(100vh - 230px)" }}
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Tên hiển thị",
                dataIndex: "title",
                key: "title",
                render: (text, record) => (
                  <a href={record.url} target="__blank">
                    {text}
                  </a>
                ),
              },
              {
                title: "Liên kết URL",
                dataIndex: "url",
                key: "url",
              },
              {
                title: "Hành động",
                key: "action",
                width: 120,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    list: state.home.topMenuList,
  }),
  {
    getTopMenuList,
    createTopMenu,
    updateTopMenu,
    deleteTopMenu,
  }
)(TopMenu);
