import { query, ENDPOINTS } from '../utils/service';

export const getProductList = (id) => query({
  endpoint: ENDPOINTS.getProductList,
  params: {
    subcategory: id,
  },
})

export const getArticlesYearCount = (categoryId) => query({
  endpoint: ENDPOINTS.getArticleYearCount,
  params: {
    category: categoryId,
  }
})

export const getArticlesByCategory = (categoryId, page, year) => query({
  endpoint: ENDPOINTS.getArticleList,
  params: {
    category: categoryId,
    year,
    limit: (!page || page === 0) ? 10 : 9,
    skip: (!page || page === 0) ? 0 : (page * 9) + 1,
  }
})

export const getArticlesBySR = (srId, page) => query({
  endpoint: ENDPOINTS.getArticleList,
  params: {
    shareholderMeeting: srId,
    limit: (!page || page === 0) ? 10 : 9,
    skip: (!page || page === 0) ? 0 : (page * 9) + 1,
  }
})

export const getCategoryDetail = (id) => query({
  endpoint: ENDPOINTS.getCategoryDetail + id,
})

export const getHome = () => query({
  endpoint: ENDPOINTS.getHome,
})