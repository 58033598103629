import React, { Component } from 'react';
import { Tabs } from 'antd';
import Layout from '../../layouts/admin';

import TopMenu from './config-components/top-menu';
import ProductMenu from './config-components/product-menu';
import FooterMenu from './config-components/footer-menu';
import SocialLink from './config-components/social-link';

class ConfigDisplay extends Component {
  render() {
    return (
      <Layout location='/admin/config-display'>
        <Tabs defaultActiveKey="0" type="card" style={{ marginBottom: 0 }}>
          <Tabs.TabPane tab="Trình đơn trên" key="0">
            <TopMenu />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Trình đơn danh mục" key="1">
            <ProductMenu />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Liên kết chân trang" key="2">
            <FooterMenu />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Mạng xã hội" key="3">
            <SocialLink />
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

export default ConfigDisplay;
