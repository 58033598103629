import React, { Fragment } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import ContentLoader from 'react-content-loader';

import { media } from '../utils/media';
import { API_URL } from '../utils/service';
import Wrapper from './wrapper';

const WrapperDesktop = styled.div`
  overflow: hidden;
  width: 100%;
  ${media.thone`
    display: none;
  `};
  .slick-dots {
    bottom: -25px !important;
    button::before {
      color: #229BD4 !important;
    }
    .slick-active {
      button::before {
        color: #134E8F !important;
      } 
    }
  }
`;

const WrapperMobile = styled.div`
  display: none;
  width: 100%;
  overflow: hidden;
  ${media.thone`
    display: block;
  `};
  .slick-list {
    /* overflow: visible !important; */
  }
`;

const Item = styled.img`
  /* background-image: url(${props => encodeURIComponent(props.src)});
  background-repeat: no-repeat;
  background-size: cover; */
  height: 100%;
  cursor: pointer;
  /* object-fit: contain;
  ${media.giant`
    height: 100%;
  `}; */
  width: 100%;
  min-height: 34vh;
`;

const ItemSkeleton = styled.div`
  /* background-image: url(${props => encodeURIComponent(props.src)});
  background-repeat: no-repeat;
  background-size: cover; */
  height: 46vh;
  ${media.desktop`
    height: 34vh;
  `};
  ${media.tablet`
    height: 30vh;
  `};
  ${media.phablet`
    height: 28vh;
  `};
  ${media.thone`
    height: 40vh;
  `};
  width: 100%;
`;

// const Content = styled.div`
//   padding: 30px 30px 30px 0px;
//   flex: 1;
//   flex-grow: 1;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   min-height: 34vh;
// `

// const Title = styled.h1`
//   font-size: 24px;
//   font-weight: 600;
//   margin-bottom: 25px;
//   text-transform: uppercase;
//   color: #134E8F;
//   ${media.phablet`
//     font-size: 20px;
//   `};
// `;

// const Subtitle = styled.h2`
//   font-size: 20px;
//   font-weight: 500;
//   margin-bottom: 15px;
//   color: #606060;
//   ${media.phablet`
//     font-size: 18px;
//   `};
// `;

// const Description = styled.h4`
//   font-size: 16px;
//   font-weight: 500;
//   margin-bottom: 20px;
//   color: #606060;
//   ${media.phablet`
//     font-size: 18px;
//   `};
// `;

const HomeSlider = ({ data, mobile, loading }) => (
  <Fragment>
    <WrapperDesktop>
      <Wrapper wrapperStyle={{overflow: 'hidden', paddingBottom: 20}}>
        <Slider
          autoplay
          autoplaySpeed={4000}
          swipeToSlide
          slidesToShow={1}
          dots
          arrows={false}
          style={{ overflow: 'visible' }}
        >
          {loading ? <ItemSkeleton>
            <ContentLoader
              height='100vh'
              width='100vw'
              speed={2}
              primaryColor="#f3f3f3"
              style={{ width: '100%', height: '100%' }}
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
            </ContentLoader>
          </ItemSkeleton> : data.map(item => (
            // <Row key={item.featuredImage}>
            //   <Col lg={8}>
            //     <Content>
            //       <Subtitle>{item.subTitle}</Subtitle>
            //       <Title>{item.title}</Title>
            //       <Description>{item.description}</Description>
            //       <div>
            //         <Button onClick={() => window.open(item.link)} type='primary' size='large'>Mua ngay</Button>
            //       </div>
            //     </Content>
            //   </Col>
            //   <Col lg={16}>
            //     <Item onClick={() => window.open(item.link)} src={`${API_URL}/upload/${item.featuredImage}`} />
            //   </Col>
            // </Row>
            <Item key={item.featuredImage} onClick={() => window.open(item.link)} src={`${API_URL}/upload/${item.featuredImage}`} />
          ))}
        </Slider>
      </Wrapper>
    </WrapperDesktop>
    <WrapperMobile>
      <Slider
        autoplay
        autoplaySpeed={4000}
        swipeToSlide
        dots
        arrows={false}
        style={{ overflow: 'visible' }}
      >
        {mobile.map(item => (
          <Item onClick={() => window.open(item.link)} key={item.featuredImage} src={`${API_URL}/upload/${item.featuredImage}`} />
        ))}
      </Slider>
    </WrapperMobile>
  </Fragment>
);

export default HomeSlider;
