import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
  GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  LOGOUT,
  REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  requesting: false,
  isLogged: false,
  userInfo: null,
  userList: [],
  totalUser: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        requesting: false,
        userInfo: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case GET_USER_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        userList: action.payload.users,
        totalUser: action.payload.total,
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        userList: [
          action.payload,
          ...state.userList,
        ],
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.map(user => (user._id === action.payload._id ? action.payload : user)),
        userInfo: action.payload._id !== state.userInfo._id ? state.userInfo : { ...state.userInfo, ...action.payload },
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.filter(user => (user._id !== action.payload._id)),
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
