import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tag, Modal, Button } from 'antd';

import DetailOrder from '../../components/detail-order';
import { getMyOrderList } from '../../actions';
import { status } from '../../pages/admin/order-all';
class ManageOrder extends Component {
  state = { showModal: false, selected: null, selectedRowKeys: [] }

  componentDidMount() {
    this.props.getMyOrderList();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  hideModal = () => {
    this.setState({ showModal: false, selected: null });
  }

  handleView = record => {
    this.setState({ showModal: true, selected: record });
  }

  render() {
    const { showModal, selectedRowKeys, selected } = this.state;
    const { list } = this.props;

    return (
      <div>
        <h2 style={{marginBottom: 16}}>Đơn hàng</h2>
        <div style={{ width: '100%', overflow: 'scroll' }}>
          <Table
            bordered
            rowKey={record => record._id}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
            }}
            columns={[{
              title: 'Trạng thái',
              key: 'currentStatus',
              dataIndex: 'currentStatus',
              render: text => (<Tag color={status.find(i => i.code === text) ? status.find(i => i.code === text).color : null}>
                {status.find(i => i.code === text)
                  ? status.find(i => i.code === text).title.toUpperCase()
                  : 'KHÔNG XÁC ĐỊNH'}
              </Tag>),
            }, {
              title: 'Phương thức',
              dataIndex: 'method',
              key: 'method',
              render: text => <Tag>{text.toUpperCase()}</Tag>,
            }, {
              title: 'Tổng thanh toán',
              dataIndex: 'totalPayment',
              key: 'totalPayment',
              render: text => <span>{text.toLocaleString()}₫</span>,
            }, {
              title: 'Thời gian',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: text => <span>{moment(text).fromNow()}</span>,
            }, {
              title: 'Hành động',
              key: 'action',
              render: (record) => (
                <span>
                  <a role='button' onClick={() => this.handleView(record)}>Chi tiết</a>
                </span>
              ),
            }]}
            dataSource={list}
          />
        </div>
        <Modal
          centered
          title={<span>Xem đơn đặt hàng</span>}
          open={showModal}
          forceRender
          onOk={this.hideModal}
          onCancel={this.hideModal}
          footer={[
            <Button key="submit" type="primary" onClick={this.hideModal}>Xong</Button>,
          ]}
        >
          {selected && (
            <DetailOrder readOnly data={selected} />
          )}
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  list: state.order.myList,
  total: state.order.myTotal,
}), {
  getMyOrderList,
})(ManageOrder);
