import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import * as Icon from '@ant-design/icons';

import logo from '../images/logo-white.jpeg';
import { media } from '../utils/media';

const Container = styled.div`
  background: #3980F6;
`;

const Top = styled.div`
  background: white;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
`;

const HotlineInfo = styled(Row)`
  overflow: hidden;
  padding: 10px 10px 5px;
  /* border-top: 1px solid #ccc; */
`;

const HotlineInfoItem = styled(Col)`
  margin-bottom: 5px;
  margin-top: 5px;
  span {
    display: block;
    float: left;
    color: #3980F6;
    padding: 0 10px 0 0;
    font-size: 20px;
    height: 40px;
    width: 40px;
    ${media.thone`
      font-size: 16px;
    `};
  }
  p {
    color: #3980F6;
    font-size: 12px;
    font-weight: 300;
    margin: 0px;
  }
  a {
    font-size: 16px;
    font-weight: 600;
    ${media.thone`
      font-size: 13px;
    `};
    color: #3980F6;
    text-decoration: none;
    ::after {
      background-color: #3980F6;
    }
  }
`;

const AboutSection = styled.div`
  padding: 20px 0px;
  margin: 0;
  ::after {
    content: '';
    display: block;
    clear: both;
  }
  a {
    ::after {
      display: none;
    }
  }
  p {
    color: rgba(255,255,255,.5);
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
  h5 {
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 1.5;
    padding-bottom: 0px;
    color: rgba(255,255,255,.86);
  }
  div {
    a {
      color: rgba(255,255,255,.5);
      display: block;
      padding: 8px 0;
      line-height: 1.8;
      font-size: 12px;
      margin: 0;
      padding: 0;
      font-weight: 300;
      text-decoration: none;
      :hover {
        color: white;
      }
      ::after {
        display: none;
      }
    }
  }
`;

const LastRow = styled.div`
  border: 0;
  display: table;
  padding: 10px 10px 20px;
  width: 100%;
  font-weight: 300;
  overflow: hidden;
  div:nth-child(1) {
    width: 50%;
    display: table-cell;
    span {
      font-size: 14px;
    }
    float: left !important;
    color: rgba(255,255,255,.5);
    vertical-align: middle;
    ${media.thone`
      width: 100%;
      display: block;
    `};
  }
  div:nth-child(2) {
    width: 50%;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    float: right !important;
    ${media.thone`
      display: block;
      text-align: left;
      float: left !important;
      width: 100%;
    `};
    a {
      display: inline-block;
      color: rgba(255,255,255,.5);
      font-size: 12px;
      padding-right: 20px;
      text-decoration: none;
      :hover {
        color: white;
      }
      ::after {
        display: none;
      }
    }
  }
`;

const Footer = ({ data, locale, contacts }) => (
  <Fragment>
    <Top>
      <Wrapper>
        <HotlineInfo>
          {contacts.map(item => (
            <HotlineInfoItem key={item._id} lg={3} md={4} sm={6} xs={6}>
              <span>
                {item.icon === 'phone' ? <Icon.PhoneOutlined /> : <Icon.MailOutlined />}
              </span>
              <p>{locale === 'en' && item.engName ? item.engName : item.name}</p>
              <a target='__blank' href={item.icon === 'phone' ? `tel:${item.value}` : `mailto:${item.value}`}>{item.value}</a>
            </HotlineInfoItem>
          ))}
        </HotlineInfo>
      </Wrapper>
    </Top>
    <Container>
      <Wrapper>
        <AboutSection>
          <Row style={{ padding: '0px 10px' }}>
            <Col lg={3} md={4} sm={6} xs={6} style={{ marginBottom: 10 }}>
              <a href="/" style={{ padding: '0 0 10px' }}>
                <img style={{ maxHeight: 40 }} src={logo} alt="Thắng Lợi logo" title="Thắng Lợi logo" />
              </a>
              {locale === 'en'
                ? <p>5 Đ. 53, Phường 14, Gò Vấp, Thành phố Hồ Chí Minh</p>
                : <p>5 Đ. 53, Phường 14, Gò Vấp, Thành phố Hồ Chí Minh</p>}
            </Col>

            {data && data.map(item => (
              <Col lg={3} md={4} sm={6} xs={6} key={item._id} style={{ marginBottom: 10 }}>
                <h5>{locale === 'en' && item.engTitle ? item.engTitle : item.title}</h5>
                <div>
                  {item.links.map(link => (
                    <a key={link.name} href={link.url}>{locale === 'en' && link.engName ? link.engName : link.name}</a>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </AboutSection>
      </Wrapper>
    </Container>
    <Container style={{ backgroundColor: '#216deb' }}>
      <Wrapper>
        <LastRow>
          <div>
            <span>Copyright © 2022 - Thắng Lợi. All Rights Reserved.</span>
          </div>
          {/* <div>
            <a target='__blank' href="/chinh-sach-bao-mat">Chính sách bảo mật</a>
            <a target='__blank' href="/dieu-khoan-su-dung">Điều khoản sử dụng</a>
            <a target='__blank' href="/phan-hoi">Phản hồi</a>
          </div> */}
        </LastRow>
      </Wrapper>
    </Container>
  </Fragment>
);

export default Footer;
