import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Table,
  // Avatar,
  Divider,
  Form,
  Modal,
  message,
  Switch,
  Checkbox,
} from 'antd';
import { connect } from 'react-redux';

import {
  getArticleCategoryList,
  createArticleCategory,
  updateArticleCategory,
  deleteArticleCategory,
} from "../../actions";
import Layout, { Paper } from "../../layouts/admin";
import Media from "../../components/media";
import CopyToClipboard from "react-copy-to-clipboard";
// import { API_URL } from '../../utils/service';

const ArticleCategory = ({ getArticleCategoryList, categories, createArticleCategory, updateArticleCategory, deleteArticleCategory }) => {
  const [form] = Form.useForm();
  const isShareholderRelation = Form.useWatch('isShareholderRelation', form);

  const [{ showMedia, showModal, modalType, selectedRowKeys }, setState] = useState(
    {
      showMedia: false,
      showModal: false,
      modalType: 'Tạo mới',
      selectedRowKeys: [],
    }
  );

  useEffect(() => {
    getArticleCategoryList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSelectChange = (selectedRowKeys) => {
    setState({ selectedRowKeys });
  }

  const setShowModal = modalType => setState({
    showModal: true,
    modalType,
  })

  const hideModal = () => {
    setState({ showModal: false });
    form.resetFields();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then(values => {
      if (modalType === 'Tạo mới') {
        createArticleCategory(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateArticleCategory(id, values);
      } else if (modalType === "Xoá") {
        deleteArticleCategory(values._id);
      }
      setState({ showModal: false });
      form.resetFields();
    });
  };

  const handleEdit = record => {
    form.setFieldsValue(record);
    setShowModal('Chỉnh sửa');
  }

  const handleDelete = record => {
    form.setFieldsValue(record);
    setShowModal('Xoá');
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Layout location='/admin/article-category'>
      <Media
        visible={showMedia}
        onCancel={() => setState({ showMedia: false })}
        onSubmit={file => {
          form.setFieldsValue({ featuredImage: file.filename });
          setState({ showMedia: false });
        }}
      />
      <Modal
        title={`${modalType} danh mục`}
        open={showModal}
        onOk={handleSubmit}
        forceRender
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>Huỷ</Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>Xác nhận</Button>,
        ]}
      >
        <Form onSubmit={handleSubmit} form={form}>
          <Form.Item noStyle name='_id'>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Tên danh mục"
            name='name'
            rules={[{
              required: true, message: 'Vui lòng nhập tên danh mục!',
            }]}
          >
            <Input disabled={modalType === 'Xoá'} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="Quan hệ cổ đông"
            name='isShareholderRelation'
          >
            <Switch disabled={modalType === 'Xoá'} />
          </Form.Item>
          {isShareholderRelation && (
            <Form.Item
              {...formItemLayout}
              label="Vị trí"
              name='position'
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Paper
        title='Danh mục danh mục'
        extra={<div>
          <Input.Search placeholder='Tìm kiếm' style={{ width: 200, marginRight: 20 }} />
          <Button onClick={() => setShowModal('Tạo mới')} type='primary'>Tạo mới</Button>
        </div>}
      >
        <Table
          bordered
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={[{
            title: 'Tên danh mục',
            dataIndex: 'name',
            key: 'name',
          }, {
            title: 'Quan hệ cổ đông',
            key: 'shareholderRelation',
            children: [
              {
                title: 'Bật',
                dataIndex: 'isShareholderRelation',
                key: 'isShareholderRelation',
                render: data => data ? <Checkbox checked disabled /> : <Checkbox disabled />,
              },
              {
                title: 'Vị trí',
                dataIndex: 'position',
                key: 'position',
              }
            ]
          }, {
            title: 'Người tạo',
            dataIndex: ['createdBy', 'fullname'],
            key: 'createdBy',
            render: data => <a>{data}</a>,
          }, {
            title: 'Hành động',
            key: 'action',
            width: 200,
            render: (record) => (
              <span>
                <a role='button' onClick={() => handleEdit(record)}>Sửa</a>
                <Divider type="vertical" />
                <a role='button' onClick={() => handleDelete(record)}>Xoá</a>
                <Divider type="vertical" />
                <CopyToClipboard text={`/blog/${record._id}`} onCopy={() => message.success('Copy URL thành công!')}>
                  <a>Copy URL</a>
                </CopyToClipboard>
              </span>
            ),
          }]}
          dataSource={categories}
        />
      </Paper>
    </Layout>
  );
}

export default connect(
  (state) => ({
    categories: state.article.categoryList,
  }),
  {
    getArticleCategoryList,
    createArticleCategory,
    updateArticleCategory,
    deleteArticleCategory,
  }
)(ArticleCategory);
