import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import moment from 'moment';

import { API_URL } from '../utils/service';

const Wrapper = styled.div`
  min-height: 350px;
  width: 100%;
  margin-bottom: 15px;
  a {
    display: block;
    ::after {
      display: none;
    }
  }
`;

export const ArticleImage = styled.div`
  background-image: url("${props => props.image}");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 240px;
  ::after {
    display: none;
  }
  cursor: pointer;
`;

const ArticleInfo = styled.div`
  padding: 10px;
`;

export const ArticleName = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 500;
  color: #134E8F;
  margin-bottom: 7px;
  -webkit-line-clamp: 2;
  line-height: 18px;
  max-height: 36px;
  ::after {
    display: none;
  }
  :hover {
    color: #134E8F;
  }
`;

export const ArticleMeta = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const ArticleDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
`;

const ArticleCard = ({ data, locale }) => (
  <Wrapper>
    <Link to={`/detail-article/${data._id}`}>
      <ArticleImage image={`${API_URL}/upload/THUMB-${data.featuredImage}`} />
    </Link>
    <ArticleInfo>
      <ArticleName to={`/detail-article/${data._id}`}>{locale === 'en' && data.engTitle ? data.engTitle : data.title}</ArticleName>
      <ArticleMeta><strong>{data.category.name}</strong> | {data.year ? `Năm ${data.year}` : moment(data.createdAt).fromNow()}</ArticleMeta>
      <ArticleDescription>{locale === 'en' && data.engExcerpt ? data.engExcerpt : data.excerpt}</ArticleDescription>
    </ArticleInfo>
  </Wrapper>
);

export default ArticleCard;
