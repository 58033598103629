import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Badge, Card, Spin, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import Layout from '../layouts/layout';
import Breadcrumb from '../components/breadcrumb';
import { getArticlesYearCount } from '../queries/page';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { getShareholderMeetingList } from '../queries/shareholderMeeting';

const Container = styled.div`
  width: 100%;
  background: #EFF0F5;
  overflow: hidden;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 25px 10px;
  background: white;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 14px;
  min-height: 70vh;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #0b3a70;
  margin-left: 15px;
  margin-bottom: 20px;
`;

const PageBreadcrumb = ({ intl }) =>
  <Breadcrumb data={[intl.formatMessage({ id: 'home' }), intl.formatMessage({ id: 'news.title' })]} />;

const InjectedPageBreadcumb = injectIntl(PageBreadcrumb);

const ShareholderMeetingList = () => {
  const locale = useSelector(state => state.setting.locale)
  const {data: countResult, error} = useQuery(
    ['getArticlesByCategory', '63bb940f81cac63b2547ffaa'],
    () => getArticlesYearCount('63bb940f81cac63b2547ffaa'),
    {select: data => data?.data || {}}
  )
  const { data, isFetching } = useQuery('shareholderMeeting', getShareholderMeetingList, {
    select: data => data ? data.data : []
  })

  return (
    <Layout locale={locale}>
      <Container>
        <InjectedPageBreadcumb />
        <Wrapper>
          <Title>
            Đại Hội Cổ Đông
          </Title>
          {error && <p><FormattedMessage id='error' /></p>}
          <Spin spinning={isFetching}>
            <Grid fluid>
              <Row gutter={16}>
                {(data || []).map(item => {
                  if (countResult?.[item._id] > 0) {
                    return (
                      <Col key={item._id} xs={6} md={6} lg={4} style={{marginBottom: 16}}>
                        <Link to={`/shareholder-meeting/${item._id}`} style={{display: 'block'}} className="no-underline">
                          <Badge.Ribbon text={countResult?.[item._id]}>
                            <Card hoverable style={{}}>
                              <Typography.Title style={{marginBottom: 0, fontWeight: 500}} level={4}>{item.name}</Typography.Title>
                            </Card>
                          </Badge.Ribbon>
                        </Link>
                      </Col>
                    )
                  }
                  return (
                    <Col key={item._id} xs={6} md={6} lg={4} style={{marginBottom: 16}}>
                      <Link to={`/shareholder-meeting/${item._id}`} style={{display: 'block'}} className="no-underline">
                        <Card hoverable style={{}}>
                          <Typography.Title style={{marginBottom: 0, fontWeight: 500}} level={4}>{item.name}</Typography.Title>
                        </Card>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            </Grid>
          </Spin>
        </Wrapper>
      </Container>
    </Layout>
  );
}

export default ShareholderMeetingList;
