import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const MyMapComponent = withScriptjs(withGoogleMap(({ location, ...props }) =>
  (<GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: location.lat, lng: location.lng }}
    {...props}
  >
    {props.isMarkerShown && <Marker position={{ lat: location.lat, lng: location.lng }} />}
  </GoogleMap>)
));

class Map extends Component {
  render() {
    const coords = { lat: 10.8488417, lng: 106.6427276 };
    return (
      <MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBDZzK67HhieFWafYnMf0vFYw6Hr0eV1J8&callback=initMap"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        location={coords}
      />
    );
  }
}

export default Map;
