import React, { Component, Fragment } from "react";
import { Input, Button, Table, Divider, Form, Modal, Select } from "antd";
// import * as Icon from '@ant-design/icons';
import { connect } from "react-redux";

import {
  getSocialLinkList,
  createSocialLink,
  updateSocialLink,
  deleteSocialLink,
} from "../../../actions";
import { Paper } from "../../../layouts/admin";

const icons = [
  "facebook",
  "youtube",
  "google-plus",
  "linkedin",
  "instagram",
  "twitter",
];

class SocialLink extends Component {
  formRef = React.createRef();

  state = {
    showModal: false,
    modalType: "Tạo mới",
  };

  componentDidMount() {
    this.props.getSocialLinkList();
  }

  showModal = (modalType) =>
    this.setState({
      showModal: true,
      modalType,
    });

  hideModal = () => {
    this.setState({ showModal: false });
    this.formRef.current?.resetFields();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    const { modalType, links } = this.state;
    const { createSocialLink, updateSocialLink, deleteSocialLink } = this.props; // eslint-disable-line
    this.formRef.current?.validateFields().then((values) => {
      values.links = links; // eslint-disable-line
      if (modalType === "Tạo mới") {
        createSocialLink(values);
      } else if (modalType === "Chỉnh sửa") {
        const id = values._id;
        delete values._id; // eslint-disable-line
        updateSocialLink(id, values);
      } else if (modalType === "Xoá") {
        deleteSocialLink(values._id);
      }
      this.setState({ showModal: false, links: [] });
      this.formRef.current?.resetFields();
    });
  };

  handleEdit = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Chỉnh sửa");
  };

  handleDelete = (record) => {
    this.formRef.current?.setFieldsValue(record);
    this.showModal("Xoá");
  };

  render() {
    const { list } = this.props;
    const { showModal, modalType } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Modal
          title={`${modalType} liên kết`}
          open={showModal}
          centered
          forceRender
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={[
            <Button key="back" onClick={this.hideModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Xác nhận
            </Button>,
          ]}
        >
          <Form onFinish={this.handleSubmit} ref={this.formRef}>
            <Form.Item noStyle name="_id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Icon mạng xã hội"
              name="icon"
              rules={[{ required: true, message: "Vui lòng chọn icon!" }]}
            >
              <Select disabled={modalType === "Xoá"}>
                {icons.map((icon) => (
                  <Select.Option key={icon} value={icon}>
                    {icon}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Liên kết"
              name="url"
              rules={[{ required: true, message: "Vui lòng nhập liên kết!" }]}
            >
              <Input disabled={modalType === "Xoá"} />
            </Form.Item>
          </Form>
        </Modal>
        <Paper
          style={{ minHeight: "calc(100vh - 230px)" }}
          extra={
            <div>
              <Input.Search
                placeholder="Tìm kiếm"
                style={{ width: 200, marginRight: 20 }}
              />
              <Button onClick={() => this.showModal("Tạo mới")} type="primary">
                Tạo mới
              </Button>
            </div>
          }
        >
          <Table
            bordered
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Icon",
                dataIndex: "icon",
                key: "icon",
                // render: (text) => <Icon type={text} />,
              },
              {
                title: "Liên kết",
                dataIndex: "url",
                key: "url",
              },
              {
                title: "Hành động",
                key: "action",
                width: 120,
                render: (record) => (
                  <span>
                    <a role="button" onClick={() => this.handleEdit(record)}>
                      Sửa
                    </a>
                    <Divider type="vertical" />
                    <a role="button" onClick={() => this.handleDelete(record)}>
                      Xoá
                    </a>
                  </span>
                ),
              },
            ]}
            dataSource={list}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    list: state.home.socialLinks,
  }),
  {
    getSocialLinkList,
    createSocialLink,
    updateSocialLink,
    deleteSocialLink,
  }
)(SocialLink);
